export default {
	date: {
		month_full: ["كانون الثاني", "شباط", "آذار", "نيسان", "أيار", "حزيران", "تموز", "آب", "أيلول", "تشرين الأول", "تشرين الثاني", "كانون الأول"],
		month_short: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"],
		day_full: ["الأحد", "الأثنين", "ألثلاثاء", "الأربعاء", "ألحميس", "ألجمعة", "السبت"],
		day_short: ["احد", "اثنين", "ثلاثاء", "اربعاء", "خميس", "جمعة", "سبت"]
	},
	labels: {
		dhx_cal_today_button: "اليوم",
		day_tab: "يوم",
		week_tab: "أسبوع",
		month_tab: "شهر",
		new_event: "حدث جديد",
		icon_save: "اخزن",
		icon_cancel: "الغاء",
		icon_details: "تفاصيل",
		icon_edit: "تحرير",
		icon_delete: "حذف",
		confirm_closing: "التغييرات سوف تضيع, هل انت متأكد؟", //Your changes will be lost, are your sure ?
		confirm_deleting: "الحدث سيتم حذفها نهائيا ، هل أنت متأكد؟",
		section_description: "الوصف",
		section_time: "الفترة الزمنية",
		full_day: "طوال اليوم",

		confirm_recurring: "هل تريد تحرير مجموعة كاملة من الأحداث المتكررة؟",
		section_recurring: "تكرار الحدث",
		button_recurring: "تعطيل",
		button_recurring_open: "تمكين",
		button_edit_series: "تحرير سلسلة",
		button_edit_occurrence: "تعديل نسخة",

		/*grid view extension*/
		grid_tab: "جدول",

		/* touch tooltip*/
		drag_to_create: "Drag to create",
		drag_to_move: "Drag to move",

		/* dhtmlx message default buttons */
		message_ok: "OK",
		message_cancel: "Cancel",

		/* wai aria labels for non-text controls */
		next: "Next",
		prev: "Previous",
		year: "Year",
		month: "Month",
		day: "Day",
		hour: "Hour",
		minute: "Minute",

		/* recurring event components */
		repeat_radio_day: "يومي", //name="repeat" value="day"
		repeat_radio_week: "أسبوعي", //name="repeat" value="week
		repeat_radio_month: "شهري",
		repeat_radio_year: "سنوي",
		repeat_radio_day_type: "كل",
		repeat_text_day_count: "يوم",
		repeat_radio_day_type2: "كل يوم عمل",
		repeat_week: " تكرار كل",
		repeat_text_week_count: "أسبوع في الأيام التالية:",
		repeat_radio_month_type: "تكرار",
		repeat_radio_month_start: "في",
		repeat_text_month_day: "يوم كل",
		repeat_text_month_count: "شهر",
		repeat_text_month_count2_before: "كل",
		repeat_text_month_count2_after: "شهر",
		repeat_year_label: "في",
		select_year_day2: "من",
		repeat_text_year_day: "يوم",
		select_year_month: "شهر",
		repeat_radio_end: "بدون تاريخ انتهاء",
		repeat_text_occurences_count: "تكرارات",
		repeat_radio_end2: "بعد",
		repeat_radio_end3: "ينتهي في",

		repeat_never: "أبداً",
		repeat_daily: "كل يوم",
		repeat_workdays: "كل يوم عمل",
		repeat_weekly: "كل أسبوع",
		repeat_monthly: "كل شهر",
		repeat_yearly: "كل سنة",
		repeat_custom: "تخصيص",
		repeat_freq_day: "يوم",
		repeat_freq_week: "أسبوع",
		repeat_freq_month: "شهر",
		repeat_freq_year: "سنة",
		repeat_on_date: "في التاريخ",
		repeat_ends: "ينتهي",

		month_for_recurring: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"],
		day_for_recurring: ["الأحد", "الإثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"]
	}
};


