export default function extend(scheduler) {


scheduler._init_touch_events = function(){
	var mobile = this.config.touch  &&
		( ((navigator.userAgent.indexOf("Mobile")!=-1)   ||
			(navigator.userAgent.indexOf("iPad")!=-1)       ||
			(navigator.userAgent.indexOf("Android")!=-1)    ||
			(navigator.userAgent.indexOf("Touch")!=-1)) && !window.MSStream) ||
			((navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));

	if(mobile){
		this.xy.scroll_width = 0;
		this._mobile = true;
	}

	if(this.config.touch){

		var touchEventsSupported = true;
		try {
			document.createEvent("TouchEvent");
		} catch (e) {
			touchEventsSupported = false;
		}

		if (touchEventsSupported) {
			this._touch_events(["touchmove", "touchstart", "touchend"], function (ev) {
				if (ev.touches && ev.touches.length > 1) return null;
				if (ev.touches[0])
					return {
						target: ev.target,
						pageX: ev.touches[0].pageX,
						pageY: ev.touches[0].pageY,
						clientX: ev.touches[0].clientX,
						clientY: ev.touches[0].clientY
					};
				else
					return ev;
			}, function () {
				return false;
			});
		} else if (window.PointerEvent || window.navigator.pointerEnabled) {
			this._touch_events(["pointermove", "pointerdown", "pointerup"], function (ev) {
				if (ev.pointerType == "mouse") return null;
				return ev;
			}, function (ev) {
				return (!ev || (ev.pointerType == "mouse" ));
			});
		} else if (window.navigator.msPointerEnabled) {
			this._touch_events(["MSPointerMove", "MSPointerDown", "MSPointerUp"], function (ev) {
				if (ev.pointerType == ev.MSPOINTER_TYPE_MOUSE) return null;
				return ev;
			}, function (ev) {
				return (!ev || ev.pointerType == ev.MSPOINTER_TYPE_MOUSE);
			});
		}
	}
};

scheduler._touch_events = function(names, accessor, ignore){
	//webkit on android need to be handled separately
	//var a_webkit = (navigator.userAgent.indexOf("Android")!=-1) && (navigator.userAgent.indexOf("WebKit")!=-1);
	var source, tracker, timer, drag_mode, scroll_mode, action_mode;
	var dblclicktime = 0;

	function attachTouchEvent(element, name, callback){
		//touch gestures must be disabled when ligthbox is opened
		scheduler.event(element, name, function(e){
			if(scheduler._is_lightbox_open()){
				return true;
			}else{
				if (ignore(e)) return;
				return callback(e);
			}
		}, { passive: false });
	}

	function check_direction_swipe(s_ev, e_ev, step, max_dy){
		if (!s_ev || !e_ev) return false;

		var t = s_ev.target;
		while(t && t != scheduler._obj){
			t = t.parentNode;
		}
		if(t != scheduler._obj){
			//swipe outside scheduler
			return false;
		}

		// ignore swipe in horizontal timeline
		if(scheduler.matrix && scheduler.matrix[scheduler.getState().mode]){
			var timeline = scheduler.matrix[scheduler.getState().mode];
			if(timeline.scrollable){
				return false;
			}
		}

		var dy = Math.abs(s_ev.pageY - e_ev.pageY);
		var dx = Math.abs(s_ev.pageX - e_ev.pageX);
		if (dy < max_dy && dx>step && (!dy || (dx/dy > 3))){
			if (s_ev.pageX > e_ev.pageX) {
				scheduler._click.dhx_cal_next_button();
			}else {
				scheduler._click.dhx_cal_prev_button();
			}
			return true;
		}
		return false;
	}

	function doMouseMove(e){
		if (ignore(e)) return;
		var dnd = scheduler.getState().drag_mode,
			timeline = scheduler.matrix ? scheduler.matrix[scheduler._mode] : false;

		var original_render = scheduler.render_view_data;
		if(dnd == 'create' && timeline){
			//suppress full redraw of timeline on creating event
			scheduler.render_view_data = function() {
				var id = scheduler.getState().drag_id;
				var ev = scheduler.getEvent(id);
				var property = timeline.y_property;

				var evs = scheduler.getEvents(ev.start_date, ev.end_date);
				for(var i = 0; i < evs.length; i++){
					if(evs[i][property] != ev[property]){
						evs.splice(i, 1);
						i--;
					}
				}
				ev._sorder = evs.length - 1;
				ev._count = evs.length;

				this.render_data([ev], scheduler.getState().mode);

			};
		}

		scheduler._on_mouse_move(e);

		if(dnd == 'create' && timeline){
			scheduler.render_view_data = original_render;
		}

		if (e.preventDefault)
			e.preventDefault();
		e.cancelBubble = true;
		return false;
	}

	// touchmove
	attachTouchEvent(document.body, names[0], function(e){
		if (ignore(e)) return;

		var acc = accessor(e);
		if(!acc) return;
		if (drag_mode){
			doMouseMove(acc);

			if (e.preventDefault)
				e.preventDefault();
			e.cancelBubble = true;
			scheduler._update_global_tip();
			return false;
		}
		//if (tracker && a_webkit){
		//	check_direction_swipe(tracker, accessor(e), 0);
		//}

		tracker = accessor(e);
		//ignore common and scrolling moves
		if (!action_mode) return;

		//multitouch
		if (!tracker){
			scroll_mode = true;
			return;
		}

		//target changed - probably in scroll mode

		if (source.target != tracker.target || (Math.abs(source.pageX - tracker.pageX) > 5) || (Math.abs(source.pageY - tracker.pageY) > 5)){
			scroll_mode = true;
			clearTimeout(timer);
		}

	});

	//attachTouchEvent(this._els["dhx_cal_data"][0], "scroll", drag_cancel);
	attachTouchEvent(this._els["dhx_cal_data"][0], "touchcancel", drag_cancel);
	attachTouchEvent(this._els["dhx_cal_data"][0], "contextmenu", function(e){
		if (ignore(e)) return;
		if (action_mode){
			if (e && e.preventDefault)
				e.preventDefault();
			e.cancelBubble = true;
			return false;
		}
	});

	// touchstart
	attachTouchEvent(this._obj, names[1], function(e){
		// block pull-to-refresh
		if(document && document.body){
			document.body.classList.add("dhx_cal_touch_active");
		}

		if (ignore(e)) return;
		scheduler._pointerDragId = e.pointerId;

		var fake_event;
		drag_mode = scroll_mode = false;
		action_mode = true;
		fake_event = tracker = accessor(e);

		if (!fake_event){
			scroll_mode = true;
			return;
		}

		//dbl click
		var now = new Date();

		if (!scroll_mode && !drag_mode && now - dblclicktime < 250){
			scheduler._click.dhx_cal_data(fake_event);
			window.setTimeout(function(){
				if(scheduler.$destroyed){
					return;
				}
				//fake_event.type = "dblclick";
				scheduler._on_dbl_click(fake_event);
			}, 50);

			if (e.preventDefault)
				e.preventDefault();
			e.cancelBubble = true;
			scheduler._block_next_stop = true;
			return false;
		}
		dblclicktime = now;

		//drag

		if (scroll_mode || drag_mode || !scheduler.config.touch_drag)
			return;

		var actTask = scheduler._locate_event(document.activeElement);
		var fakeTask = scheduler._locate_event(fake_event.target);
		var sourceTask = source? scheduler._locate_event(source.target) : null;

		if(actTask && fakeTask && actTask == fakeTask && actTask != sourceTask)
		{
			if(e.preventDefault) {
				e.preventDefault();
			}
			e.cancelBubble = true;
			scheduler._ignore_next_click = false;
			scheduler._click.dhx_cal_data(fake_event);
			source = fake_event;
			return false;
		}
		//there is no target
		timer = setTimeout(function(){
			if(scheduler.$destroyed){
				return;
			}
			drag_mode = true;
			var target = source.target;
			var className = scheduler._getClassName(target);
			if (target && className.indexOf("dhx_body") != -1)
				target = target.previousSibling;

			scheduler._on_mouse_down(source, target);
			if (scheduler._drag_mode && scheduler._drag_mode != "create"){
				scheduler.for_rendered(scheduler._drag_id, function(node, i) {
					node.style.display='none';
					scheduler._rendered.splice(i, 1);
				});
			}

			if (scheduler.config.touch_tip) {
				scheduler._show_global_tip();
			}
			scheduler.updateEvent(scheduler._drag_id);
		},scheduler.config.touch_drag);

		source = fake_event;
	});
	function drag_cancel(e){
		if (ignore(e)) return;
		scheduler._hide_global_tip();
		if (drag_mode){
			scheduler._on_mouse_up( accessor(e) );
			scheduler._temp_touch_block = false;
		}
		scheduler._drag_id = null;
		scheduler._drag_mode=null;
		scheduler._drag_pos=null;
		scheduler._pointerDragId = null;
		clearTimeout(timer);
		drag_mode = action_mode = false;
		scroll_mode = true;
	}

	// touch end
	attachTouchEvent(this._els["dhx_cal_data"][0], names[2], function(e){
		if(document && document.body){
			document.body.classList.remove("dhx_cal_touch_active");
		}

		if (ignore(e)) return;

		if(scheduler.config.touch_swipe_dates){
			if (!drag_mode && check_direction_swipe(source, tracker, 200, 100)) {
				scheduler._block_next_stop = true;
			}
		}

		if (drag_mode) {
			scheduler._ignore_next_click = true;
			setTimeout(function(){
				scheduler._ignore_next_click = false;
			}, 100);
		}

		drag_cancel(e);
		if (scheduler._block_next_stop){
			scheduler._block_next_stop = false;
			if (e.preventDefault)
				e.preventDefault();
			e.cancelBubble = true;
			return false;
		}
	});

	scheduler.event(document.body, names[2], drag_cancel);
};

scheduler._show_global_tip = function(){
	scheduler._hide_global_tip();

	var toptip = scheduler._global_tip = document.createElement("div");
	toptip.className='dhx_global_tip';

	scheduler._update_global_tip(1);

	document.body.appendChild(toptip);
};
scheduler._update_global_tip = function(init){
	var toptip = scheduler._global_tip;
	if (toptip){
		var time = "";
		if (scheduler._drag_id && !init){
			var ev = scheduler.getEvent(scheduler._drag_id);
			if (ev)
				time = "<div>" + (ev._timed ? scheduler.templates.event_header(ev.start_date, ev.end_date, ev):scheduler.templates.day_date(ev.start_date, ev.end_date, ev)) + "</div>";
		}

		if (scheduler._drag_mode == "create" || scheduler._drag_mode == "new-size")
			toptip.innerHTML = (scheduler.locale.labels.drag_to_create || "Drag to create")+time;
		else
			toptip.innerHTML = (scheduler.locale.labels.drag_to_move || "Drag to move")+time;
	}
};
scheduler._hide_global_tip = function(){
	var toptip = scheduler._global_tip;
	if (toptip && toptip.parentNode){
		toptip.parentNode.removeChild(toptip);
		scheduler._global_tip = 0;
	}
};


}