export default function extend(scheduler) {

(function(){

	// eslint-disable-next-line no-control-regex
	var htmlTags = new RegExp("<(?:.|\n)*?>", "gm");
	var extraSpaces = new RegExp(" +", "gm");

	function stripHTMLLite(htmlText){
		return (htmlText + "")
			.replace(htmlTags, " ").
			replace(extraSpaces, " ");
	}

	var singleQuotes = new RegExp("'", "gm");
	function escapeQuotes(text){
		return (text + "").replace(singleQuotes, "&#39;");
	}

	scheduler._waiAria = {
		getAttributeString: function(attr){
			var attributes = [" "];
			for(var i in attr){
				if(typeof attr[i] != "function" && typeof attr[i] != "object") {
					var text = escapeQuotes(stripHTMLLite(attr[i]));
					attributes.push(i + "='" + text + "'");
				}
			}
			attributes.push(" ");
			return attributes.join(" ");
		},
		setAttributes: function(div, values){
			for(var i in values){
				div.setAttribute(i, stripHTMLLite(values[i]));
			}
			return div;
		},

		labelAttr: function(div, content){
			return this.setAttributes(div, {"aria-label": content});
		},
		label: function(label){
			return scheduler._waiAria.getAttributeString({"aria-label": label});
		},

		// day/week/units

		hourScaleAttr: function(div, content){
			this.labelAttr(div, content);

		},
		monthCellAttr: function(div, date){
			this.labelAttr(div, scheduler.templates.day_date(date));
		},

		navBarDateAttr: function(div, content){
			this.labelAttr(div, content);
		},
		dayHeaderAttr: function(div, content){
			this.labelAttr(div, content);
		},

		dayColumnAttr: function(div, date){
			this.dayHeaderAttr(div, scheduler.templates.day_date(date));
		},

		headerButtonsAttributes: function(div, label){
			return this.setAttributes(div, {"role":"button", "aria-label":label});
		},

		headerToggleState: function(div, isActive){
			return this.setAttributes(div, {"aria-pressed": isActive ? "true" : "false"});
		},


		getHeaderCellAttr:function(dateString){

			return scheduler._waiAria.getAttributeString({"aria-label": dateString});
		},


		eventAttr: function(event, div){
			this._eventCommonAttr(event, div);
		},


		_eventCommonAttr: function(event, div){
			div.setAttribute("aria-label", stripHTMLLite(scheduler.templates.event_text(event.start_date, event.end_date, event)));

			if(scheduler.config.readonly){
				div.setAttribute("aria-readonly", true);

			}

			if(event.$dataprocessor_class){
				div.setAttribute("aria-busy", true);
			}


			div.setAttribute("aria-selected",
				(scheduler.getState().select_id == event.id) ? "true" : "false");
		},

		setEventBarAttr: function(event, div){
			this._eventCommonAttr(event, div);
		},

		_getAttributes: function(attributeSetter, arg){
			var result = {
				setAttribute:function(name, value){
					this[name] = value;
				}
			};

			attributeSetter.apply(this, [arg, result]);
			return result;

		},

		eventBarAttrString: function(event){
			return this.getAttributeString(this._getAttributes(this.setEventBarAttr, event));
		},



		agendaHeadAttrString :function(){
			return this.getAttributeString({role: "row"});
		},
		agendaHeadDateString :function(label){
			return this.getAttributeString({role: "columnheader", "aria-label": label});
		},
		agendaHeadDescriptionString :function(label){
			return this.agendaHeadDateString(label);
		},
		agendaDataAttrString: function(){
			return this.getAttributeString({role: "grid"});
		},
		agendaEventAttrString: function(event){
			var attrs = this._getAttributes(this._eventCommonAttr, event);

			attrs["role"] = "row";

			return this.getAttributeString(attrs);

		},
		agendaDetailsBtnString: function(){
			return this.getAttributeString({"role":"button", "aria-label":scheduler.locale.labels.icon_details});
		},


		gridAttrString: function(){
			return this.getAttributeString({role: "grid"});
		},

		gridRowAttrString: function(event){
			return this.agendaEventAttrString(event);
		},

		gridCellAttrString: function(event, column, value){
			return this.getAttributeString({"role":"gridcell", "aria-label": [
				(column.label === undefined ? column.id : column.label),
				": ",
				value
			]});
		},

		mapAttrString: function(){
			return this.gridAttrString();
		},
		mapRowAttrString: function(event){
			return this.gridRowAttrString(event);
		},
		mapDetailsBtnString: function(){
			return this.agendaDetailsBtnString();
		},

		minicalHeader: function(div, headerId){
			this.setAttributes(div, {
				"id":headerId+"",
				"aria-live":"assertice",
				"aria-atomic":"true"

			});
		},
		minicalGrid: function(div, headerId){
			this.setAttributes(div, {
				"aria-labelledby":headerId+"",
				"role":"grid"
			});
		},
		minicalRow: function(div){
			this.setAttributes(div, {
				"role":"row"
			});
		},
		minicalDayCell: function(div, date){
			var selected = (date.valueOf() < scheduler._max_date.valueOf() && date.valueOf() >= scheduler._min_date.valueOf());
			this.setAttributes(div, {
				"role":"gridcell",
				"aria-label": scheduler.templates.day_date(date),
				"aria-selected": selected ? "true" : "false"
			});
		},
		minicalHeadCell: function(div){
			this.setAttributes(div, {
				"role":"columnheader"
			});
		},


		weekAgendaDayCell: function(div, date){
			var header = div.querySelector(".dhx_wa_scale_bar");
			var content = div.querySelector(".dhx_wa_day_data");
			var headerId = scheduler.uid() + "";
			this.setAttributes(header, { "id": headerId});
			this.setAttributes(content, { "aria-labelledby": headerId});

		},
		weekAgendaEvent: function(div, event){
			this.eventAttr(event, div);
		},

		lightboxHiddenAttr: function(div){
			div.setAttribute("aria-hidden", "true");
		},

		lightboxVisibleAttr: function(div){
			div.setAttribute("aria-hidden", "false");
		},

		lightboxSectionButtonAttrString: function(label){
			return this.getAttributeString({"role":"button", "aria-label":label, "tabindex":"0"});
		},

		yearHeader: function(div, headerId){
			this.setAttributes(div, {
				"id":headerId+""
			});
		},
		yearGrid: function(div, headerId){
			this.minicalGrid(div, headerId);
		},
		yearHeadCell: function(div){
			return this.minicalHeadCell(div);
		},
		yearRow: function(div){
			return this.minicalRow(div);
		},
		yearDayCell: function(div){
			this.setAttributes(div, {
				"role":"gridcell"
			});
		},

		lightboxAttr: function(div){
			div.setAttribute("role", "dialog");
			div.setAttribute("aria-hidden", "true");
			div.firstChild.setAttribute("role", "heading");
		},

		lightboxButtonAttrString:function(buttonName){
			return this.getAttributeString({"role":"button", "aria-label":scheduler.locale.labels[buttonName], "tabindex":"0"});
		},
		eventMenuAttrString: function(iconName){
			return this.getAttributeString({"role":"button", "aria-label":scheduler.locale.labels[iconName]});
		},
		lightboxHeader: function(div, headerText){
			div.setAttribute("aria-label", headerText);
		},

		lightboxSelectAttrString: function(time_option){
			var label = "";

			switch (time_option) {
				case "%Y":
					label = scheduler.locale.labels.year;
					break;
				case "%m":
					label = scheduler.locale.labels.month;
					break;
				case "%d":
					label = scheduler.locale.labels.day;
					break;
				case "%H:%i":
					label = scheduler.locale.labels.hour + " " + scheduler.locale.labels.minute;
					break;
				default:
					break;
			}

			return scheduler._waiAria.getAttributeString({"aria-label": label});
		},


		messageButtonAttrString: function(buttonLabel){
			return "tabindex='0' role='button' aria-label='"+buttonLabel+"'";
		},

		messageInfoAttr: function(div){
			div.setAttribute("role", "alert");
			//div.setAttribute("tabindex", "-1");
		},

		messageModalAttr: function(div, uid){
			div.setAttribute("role", "dialog");
			if(uid){
				div.setAttribute("aria-labelledby", uid);
			}

			//	div.setAttribute("tabindex", "-1");
		},

		quickInfoAttr: function(div){
			div.setAttribute("role", "dialog");
		},

		quickInfoHeaderAttrString: function(){
			return " role='heading' ";
		},

		quickInfoHeader: function(div, header){
			div.setAttribute("aria-label", header);
		},

		quickInfoButtonAttrString: function(label){
			return scheduler._waiAria.getAttributeString({"role":"button", "aria-label":label, "tabindex":"0"});
		},

		tooltipAttr: function(div){
			div.setAttribute("role", "tooltip");
		},

		tooltipVisibleAttr: function(div){
			div.setAttribute("aria-hidden", "false");
		},

		tooltipHiddenAttr: function(div){
			div.setAttribute("aria-hidden", "true");
		}
	};

	function isDisabled(){
		return !scheduler.config.wai_aria_attributes;
	}

	for(var i in scheduler._waiAria){
		scheduler._waiAria[i] = (function(payload){
			return function(){
				if(isDisabled()){
					return " ";
				}
				return payload.apply(this, arguments);
			};
		})(scheduler._waiAria[i]);
	}


})();

}