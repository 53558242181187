export default function extend(scheduler) {

if (window.jQuery){

(function( $ ){
	var counter = 0;
	var methods = [];
	$.fn.dhx_scheduler = function(config){
		if (typeof(config) === 'string') {
			if (methods[config] ) {
				return methods[config].apply(this, []);
			}else {
				$.error('Method ' +  config + ' does not exist on jQuery.dhx_scheduler');
			}
		} else {
			var views = [];
			this.each(function() {
				if (this && this.getAttribute){
					if (!this.getAttribute("dhxscheduler")){
						var name = "scheduler";
						if (counter){
							name = "scheduler" + (counter+1);
							window[name] = Scheduler.getSchedulerInstance();
						}
						var comp  = window[name];

						this.setAttribute("dhxscheduler", name);
						for (var key in config)
							if (key!="data")
								comp.config[key] = config[key];

						if (!this.getElementsByTagName("div").length){
							this.innerHTML = '<div class="dhx_cal_navline"><div class="dhx_cal_prev_button"></div><div class="dhx_cal_next_button"></div><div class="dhx_cal_today_button"></div><div class="dhx_cal_date"></div><div class="dhx_cal_tab" name="day_tab" data-tab="day" style="right:204px;"></div><div class="dhx_cal_tab" name="week_tab" data-tab="week" style="right:140px;"></div><div class="dhx_cal_tab" name="month_tab" data-tab="month" style="right:76px;"></div></div><div class="dhx_cal_header"></div><div class="dhx_cal_data"></div>';
							this.className += " dhx_cal_container";
						}
						comp.init(this, comp.config.date, comp.config.mode);
						if (config.data)
							comp.parse(config.data);

						views.push(comp);
						counter++;
					} else
						views.push(window[this.getAttribute("dhxscheduler")]);
				}
			});

			if (views.length === 1) return views[0];
			return views;
		}
	};




})(window.jQuery);

}

}