export default {
	event: function (el, event, handler){
		if (el.addEventListener)
			el.addEventListener(event, handler, false);
	
		else if (el.attachEvent)
			el.attachEvent("on"+event, handler);
	},
	eventRemove: function (el, event, handler){
		if (el.removeEventListener)
			el.removeEventListener(event, handler, false);
	
		else if (el.detachEvent)
			el.detachEvent("on"+event, handler);
	}
};