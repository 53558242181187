export default function(scheduler){

scheduler.$keyboardNavigation.HeaderCell = function(index){
	this.index = index || 0;
};

scheduler.$keyboardNavigation.HeaderCell.prototype = scheduler._compose(
	scheduler.$keyboardNavigation.KeyNavNode,
	{
		getNode: function(index){
			index = index || this.index || 0;
			var nodes = this.getNodes();
			if(nodes[index]) return nodes[index];
		},

		getNodes: function(selector){
			selector = selector || [
					".dhx_cal_navline .dhx_cal_prev_button",
					".dhx_cal_navline .dhx_cal_next_button",
					".dhx_cal_navline .dhx_cal_today_button",
					".dhx_cal_navline .dhx_cal_tab"
				].join(", ");

			var nodes = Array.prototype.slice.call(scheduler.$container.querySelectorAll(selector));
			nodes.sort(function(a, b){
				return a.offsetLeft - b.offsetLeft;
			});
			return nodes;
		},

		_handlers:null,

		isValid: function(){
			return !!this.getNode(this.index);
		},
		fallback:function(){
			var defaultCell = this.getNode(0);
			if(!defaultCell){
				defaultCell = new scheduler.$keyboardNavigation.TimeSlot();
			}
			return defaultCell;
		},

		keys: {
			"left": function(){
				var newIndex = this.index - 1;
				if(newIndex < 0){
					newIndex = this.getNodes().length - 1;
				}

				this.moveTo(new scheduler.$keyboardNavigation.HeaderCell(newIndex));

			},
			"right": function () {
				var newIndex = this.index + 1;
				if(newIndex >= this.getNodes().length){
					newIndex = 0;
				}

				this.moveTo(new scheduler.$keyboardNavigation.HeaderCell(newIndex));
			},
			"down": function () {
				this.moveTo(new scheduler.$keyboardNavigation.TimeSlot());
			},

			"enter": function(){
				var node = this.getNode();
				if(node){
					node.click();
				}
			}
		}
	}
);

scheduler.$keyboardNavigation.HeaderCell.prototype.bindAll(scheduler.$keyboardNavigation.HeaderCell.prototype.keys);

}