export default function extend(scheduler) {


scheduler._parsers.xml = {
	canParse: function(data, xhr){
		// IE returns non-empty responseXML type regardless of actual data type
		if(xhr.responseXML && xhr.responseXML.firstChild){
			return true;
		}

		try{
			var xmlDoc = scheduler.ajax.parse(xhr.responseText);
			var topElement = scheduler.ajax.xmltop("data", xmlDoc);
			if(topElement && topElement.tagName === "data"){
				return true;
			}
		}catch (e){
		}
		return false;
	},
	parse: function(loader) {
		var xml;

		if (!loader.xmlDoc.responseXML) { //from a string
			loader.xmlDoc.responseXML = scheduler.ajax.parse(loader.xmlDoc.responseText);
		}
	
		xml = scheduler.ajax.xmltop("data", loader.xmlDoc);
		if (xml.tagName != "data") return null;//not an xml
		var csrfToken = xml.getAttribute("dhx_security");
		if (csrfToken) {
			if (window.dhtmlx) {
				window.dhtmlx.security_key = csrfToken;
			}
			scheduler.security_key = csrfToken;
		}
	
		var opts = scheduler.ajax.xpath("//coll_options", loader.xmlDoc);
		for (var i = 0; i < opts.length; i++) {
			var bind = opts[i].getAttribute("for");
			var arr = scheduler.serverList[bind];
			if (!arr) {
				scheduler.serverList[bind] = arr = [];
			}
			arr.splice(0, arr.length);	//clear old options
			var itms = scheduler.ajax.xpath(".//item", opts[i]);
			for (var j = 0; j < itms.length; j++) {
				var itm = itms[j];
				var attrs = itm.attributes;
				var obj = { key: itms[j].getAttribute("value"), label: itms[j].getAttribute("label")};
				for (var k = 0; k < attrs.length; k++) {
					var attr = attrs[k];
					if (attr.nodeName == "value" || attr.nodeName == "label")
						continue;
					obj[attr.nodeName] = attr.nodeValue;
				}
				arr.push(obj);
			}
		}
		if (opts.length)
			scheduler.callEvent("onOptionsLoad", []);
	
		var ud = scheduler.ajax.xpath("//userdata", loader.xmlDoc);
		for (var i = 0; i < ud.length; i++) {
			var udx = scheduler._xmlNodeToJSON(ud[i]);
			scheduler._userdata[udx.name] = udx.text;
		}
	
		var evs = [];
		xml = scheduler.ajax.xpath("//event", loader.xmlDoc);
	
		for (var i = 0; i < xml.length; i++) {
			var ev = evs[i] = scheduler._xmlNodeToJSON(xml[i]);
			scheduler._init_event(ev);
		}
		return evs;
	}
};

}