export default function extend(scheduler) {

scheduler.getRootView = function() {
	return {
		view: {
			render: function(){
				return {
					tag: "div",
					type: 1,
					attrs: {
						style: "width:100%;height:100%;"
					},
					hooks: {
						"didInsert": function(){
							scheduler.setCurrentView();
						}
					},
					body: [
						{
							el: this.el,
							type: 1
						}
					]
				};
			},
			init: function(){
				var container = document.createElement("DIV");
				container.id = "scheduler_"+ scheduler.uid();
				container.style.width = "100%";
				container.style.height = "100%";
				container.classList.add("dhx_cal_container");
				container.cmp = "grid";
				container.innerHTML = '<div class="dhx_cal_navline">' +
					'<div class="dhx_cal_prev_button"></div>' +
					'<div class="dhx_cal_next_button"></div>' +
					'<div class="dhx_cal_today_button"></div>' +
					'<div class="dhx_cal_date"></div>' +
					'<div class="dhx_cal_tab" data-tab="day"></div>' +
					'<div class="dhx_cal_tab" data-tab="week"></div>' +
					'<div class="dhx_cal_tab" data-tab="month"></div>' +
					'</div>' +
					'<div class="dhx_cal_header">' +
					'</div>' +
					'<div class="dhx_cal_data">' +
					'</div>';
				scheduler.init(container);

				this.el = container;
			}
		},
		type: 4
	};
};

}