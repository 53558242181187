import  { GoogleMapAdapter } from "./gMapAdapter";
import { OpenStreetMapAdapter } from "./osmMapAdapter";
import { MapboxAdapter } from "./mapboxAdapter";
import { IMapAdapter, ICalendarEvent, IMapSettings } from "./types";

declare let google: any;

export default function(scheduler: any){
	
let mapAdapter: IMapAdapter | null = null;
let eventHandlerIds: number[] = [];
const adapters: { [name:string]: IMapAdapter } = {
	googleMap: new GoogleMapAdapter(scheduler),
	openStreetMaps: new OpenStreetMapAdapter(scheduler),
	mapbox: new MapboxAdapter(scheduler)
}

if(!scheduler.ext){
	scheduler.ext = {};
}
scheduler.ext.mapView = {
	createAdapter: function():IMapAdapter{
		return adapters[scheduler.config.map_view_provider];
	},
	createMarker: function(config){
		return new google.maps.Marker(config);
	},
	currentAdapter: null,
	adapters
};
scheduler._latLngUpdate  = false;  // flag for updating events which don't have event.lat and event.lng
scheduler._eventLocationChanged = false;  // flag for checking if the location of the event is changed
scheduler.config.map_view_provider = "googleMap";
scheduler.config.map_settings = {
	initial_position: {
	   lat: 48.724,
	   lng: 8.215
	},
	error_position: {
	   lat: 15,
	   lng: 15
	},
	initial_zoom: 1,
	zoom_after_resolve: 15,
	info_window_max_width: 300,
	resolve_user_location: true,
	resolve_event_location: true,
	view_provider: "googleMap"
}
if (scheduler.config.map_initial_position) {
	scheduler.config.map_settings.initial_position = {
		lat: scheduler.config.map_initial_position.lat(),
		lng: scheduler.config.map_initial_position.lng(), 
	};
}

if (scheduler.config.map_error_position) {
	scheduler.config.map_settings.error_position = {
		lat: scheduler.config.map_error_position.lat(),
		lng: scheduler.config.map_error_position.lng(), 
	};
}

scheduler.xy.map_date_width = 188; // date column width
scheduler.xy.map_icon_width = 25; // event details icon width
scheduler.xy.map_description_width = 400; // description column width
scheduler.date.add_map = function(date, inc, mode) {
	return (new Date(date.valueOf()));
};
scheduler.templates.map_date = function(dd, ed, mode) {
	return '';
};
scheduler.templates.map_time = function(start, end, ev) {
	if (scheduler.config.rtl && !ev._timed) {
		return scheduler.templates.day_date(end) + " &ndash; " + scheduler.templates.day_date(start);
	} else if (ev._timed) {
		return this.day_date(ev.start_date, ev.end_date, ev) + " " + this.event_date(start);
	} else {
		return scheduler.templates.day_date(start) + " &ndash; " + scheduler.templates.day_date(end);
	}
};
scheduler.templates.map_text = function(start, end, ev) {
	return ev.text;
};
scheduler.templates.map_info_content = function(event){
    return `<div><b>Event's text:</b> ${event.text}
				<div><b>Location:</b> ${event.event_location}</div>
				<div><b>Starts:</b> ${scheduler.templates.tooltip_date_format(event.start_date)}</div>
				<div><b>Ends:</b> ${scheduler.templates.tooltip_date_format(event.end_date)}</div>
			</div>`;
};
scheduler.date.map_start = function(d) {
	return d;
};

function setupMapView(scheduler) {
	// Initialization logic, possibly including creation of a new mapAdapter
	mapAdapter = scheduler.ext.mapView.createAdapter();
	attachSchedulerEvents();
}
async function addEventWithLocation(event: ICalendarEvent, mapAdapter: IMapAdapter){
	let coordinates = await mapAdapter.resolveAddress(event.event_location);
	event.lat = coordinates.lat;
	event.lng = coordinates.lng;
	mapAdapter.removeEventMarker(String(event.id));
	mapAdapter.addEventMarker(event);
	return event;
}
function setUserLocation(options: IMapSettings, adapter: IMapAdapter): void{
	if (options.resolve_user_location) {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(function(position) {
				adapter.setView(position.coords.latitude, position.coords.longitude, options.zoom_after_resolve ||  options.initial_zoom);
			});
		}
	} else {
		adapter.setView(options.initial_position.lat, options.initial_position.lng, options.initial_zoom);
	}
}
scheduler.dblclick_dhx_map_area = function(e) {
	let eventNode = e.target.closest(`[${scheduler.config.event_attribute}]`);
	if (eventNode){
		let eventId = eventNode.getAttribute(`${scheduler.config.event_attribute}`);
		scheduler.showLightbox(eventId);
	}
	if (!this.config.readonly && this.config.dblclick_create && !eventNode) {
		this.addEventNow({
			start_date:  scheduler.config.map_start,
			end_date: scheduler.date.add( scheduler.config.map_start, scheduler.config.time_step, "minute"),
		});
	}	
};

function attachSchedulerEvents() { 
  eventHandlerIds.push(
	scheduler.attachEvent("onEventSave",function(id,ev,is_new){
		let unmodifiedEvent = scheduler.getEvent(id);
		if (unmodifiedEvent && unmodifiedEvent.event_location != ev.event_location) {
			scheduler._eventLocationChanged = true;
		}
		return true;
	}),
    scheduler.attachEvent("onEventChanged", (id, event) => {
		const {start_date, end_date} = event;
		const {min_date, max_date} = scheduler.getState();
		if(start_date.valueOf() < max_date.valueOf() && end_date.valueOf() > min_date.valueOf()){
			if (mapAdapter) {
				if (scheduler.config.map_settings.resolve_event_location && event.event_location && !scheduler._latLngUpdate ) {
				 	addEventWithLocation(event, mapAdapter);
				} else {
					mapAdapter.updateEventMarker(event);
				}
			}
		}
		scheduler._latLngUpdate  = false;
		return true;
    }),
	scheduler.attachEvent("onEventIdChange", function(old_id,new_id){
		let newIdEvent = scheduler.getEvent(new_id);
		mapAdapter?.removeEventMarker(old_id);
		mapAdapter?.addEventMarker(newIdEvent);
	}),
    scheduler.attachEvent("onEventAdded", (id, event) => {
		const {start_date, end_date} = event;
		const {min_date, max_date} = scheduler.getState();
		if(start_date.valueOf() < max_date.valueOf() && end_date.valueOf() > min_date.valueOf()){
      		if (mapAdapter) {
				if (scheduler.config.map_settings.resolve_event_location && event.event_location && scheduler._eventLocationChanged) {
					addEventWithLocation(event, mapAdapter);
					scheduler._eventLocationChanged = false;
				} else {
					mapAdapter.addEventMarker(event);
					mapAdapter.onEventClick(event);
				}
			}
		}
    }),
    scheduler.attachEvent("onClick", function (id, e){
		const event = scheduler.getEvent(id);
		if (mapAdapter && event) mapAdapter.onEventClick(event);
		return false;
    }),
    scheduler.attachEvent("onBeforeEventDelete", (id, event) => {
		if (mapAdapter) {
			mapAdapter.removeEventMarker(id);
		}
		return true;
    })
  );
}
function detachSchedulerEvents() {
	eventHandlerIds.forEach(id => scheduler.detachEvent(id));
	eventHandlerIds = [];
}

scheduler.attachEvent("onSchedulerReady", function() {

	if(scheduler.config.map_initial_zoom !== undefined){
		scheduler.config.map_settings.initial_zoom = scheduler.config.map_initial_zoom;
	}
	if(scheduler.config.map_zoom_after_resolve  !== undefined){
		scheduler.config.map_settings.zoom_after_resolve = scheduler.config.map_zoom_after_resolve;
	}
	if(scheduler.config.map_infowindow_max_width !== undefined){
		scheduler.config.map_settings.info_window_max_width = scheduler.config.map_infowindow_max_width;
	}
	if(scheduler.config.map_resolve_user_location !== undefined){
		scheduler.config.map_settings.resolve_user_location = scheduler.config.map_resolve_user_location;
	}
	if(scheduler.config.map_view_provider !== undefined){
		scheduler.config.map_settings.view_provider = scheduler.config.map_view_provider;
	}
	if(scheduler.config.map_type !== undefined){
		scheduler.config.map_settings.type = scheduler.config.map_type;
	}
	if(scheduler.config.map_resolve_event_location !== undefined){
		scheduler.config.map_settings.resolve_event_location = scheduler.config.map_resolve_event_location;
	}
	scheduler.ext.mapView.currentAdapter = scheduler.config.map_view_provider;

	let map = document.createElement('div');
	map.className = 'mapContainer';
	map.id = 'mapContainer';
	map.style.display = "none";
	map.style.zIndex = "1";
	scheduler._obj.appendChild(map);

	const old = scheduler.render_data;
	scheduler.render_data = function(evs, hold) {
		if (this._mode == "map") {
			fill_map_tab();
			let events = scheduler.get_visible_events();
			if (mapAdapter) {
				mapAdapter.clearEventMarkers();
				events.forEach((event) => mapAdapter?.addEventMarker(event));
			}
		} else
			return old.apply(this, arguments);
	};

	scheduler.map_view = function(mode) {
		scheduler._els.dhx_cal_data[0].style.width = (scheduler.xy.map_date_width + scheduler.xy.map_description_width + 1) + 'px';
		scheduler._min_date = scheduler.config.map_start || (scheduler._currentDate());
		scheduler._max_date = scheduler.config.map_end || scheduler.date.add(scheduler._currentDate(), 1, "year");
		scheduler._table_view = true;
		set_full_view(mode);
		let mapContainer = document.getElementById('mapContainer');
		_setMapSize('mapContainer');

		// creating the new logic
		if (mode && mapContainer) {
			map.style.display = 'block';
			fill_map_tab();
			// case to display several maps and has the opportunity to switch between them
			if (scheduler.config.map_view_provider == scheduler.ext.mapView.currentAdapter) {
				mapAdapter?.destroy(mapContainer);
				setupMapView(scheduler);
				mapAdapter?.initialize(mapContainer, scheduler.config.map_settings);
			} else {
				mapAdapter?.destroy(mapContainer);
				setupMapView(scheduler);
				mapAdapter?.initialize(mapContainer, scheduler.config.map_settings);
			 	scheduler.ext.mapView.currentAdapter = scheduler.config.map_view_provider;
			}
			if(mapAdapter) {
				setUserLocation(scheduler.config.map_settings, mapAdapter);
			}	
		} else {
			map.style.display = 'none';
			scheduler._els.dhx_cal_data[0].style.width = "100%";
		 	if (mapAdapter && mapContainer ) {
				mapAdapter.destroy(mapContainer);
				mapAdapter = null;
				scheduler.ext.mapView.currentAdapter = scheduler.config.map_view_provider;
		    }
		  	detachSchedulerEvents();
		}
	};

	function _setMapSize(elem_id) { //input - map's div id
		let map = document.getElementById(elem_id);
		if (map) {
			const nav_height = scheduler.$container.querySelector(".dhx_cal_navline").offsetHeight;
			let height = scheduler.$container.querySelector(".dhx_cal_data").offsetHeight + scheduler.$container.querySelector(".dhx_cal_header").offsetHeight;
			if (height < 0)
				height = 0;
			let width = scheduler._x - scheduler.xy.map_date_width - scheduler.xy.map_description_width - 1;
			if (width < 0)
				width = 0;
			map.style.height = height + 'px';
			map.style.width = width + 'px';
			map.style.position = "absolute";
			map.style.top = nav_height + "px";
			if (scheduler.config.rtl) {
				map.style.marginRight = (scheduler.xy.map_date_width + scheduler.xy.map_description_width + 1) + 'px';
			} else {
				map.style.marginLeft = (scheduler.xy.map_date_width + scheduler.xy.map_description_width + 1) + 'px';
			}
			map.style.marginTop = (scheduler.xy.nav_height + 2) + 'px';
		}
	}	
	function fill_map_tab() {
		//select events for which data need to be printed
		let events = scheduler.get_visible_events();
		events.sort(function(a, b) {
			if(a.start_date.valueOf()==b.start_date.valueOf())
				return a.id>b.id?1:-1;
			return a.start_date>b.start_date?1:-1;
		});

		//generate html for the view
		let ariaAttr = scheduler._waiAria.mapAttrString();

		let html = "<div "+ariaAttr+" class='dhx_map_area'>";
		for (let i = 0; i < events.length; i++) {
			let ev = events[i];
			let event_class = (ev.id == scheduler._selected_event_id) ? 'dhx_map_line highlight' : 'dhx_map_line';
			let bg_color = (ev.color ? ("--dhx-scheduler-event-background:" + ev.color + ";") : "");
			let color = (ev.textColor ? ("--dhx-scheduler-event-color:" + ev.textColor + ";") : "");

			let ariaAttr = scheduler._waiAria.mapRowAttrString(ev);
			let ariaButtonAttr = scheduler._waiAria.mapDetailsBtnString();

			html += "<div "+ariaAttr+" class='" + event_class + "' event_id='" + ev.id + "' "+scheduler.config.event_attribute+"='"+ev.id+"' style='" + bg_color + "" + color + "" + (ev._text_style || "") + " width: " + (scheduler.xy.map_date_width + scheduler.xy.map_description_width + 2) + "px;'><div class='dhx_map_event_time' style='width: " + scheduler.xy.map_date_width + "px;' >" + scheduler.templates.map_time(ev.start_date, ev.end_date, ev) + "</div>";
			html += `<div ${ariaButtonAttr} class='dhx_event_icon icon_details'><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M15.4444 16.4H4.55556V7.6H15.4444V16.4ZM13.1111 2V3.6H6.88889V2H5.33333V3.6H4.55556C3.69222 3.6 3 4.312 3 5.2V16.4C3 16.8243 3.16389 17.2313 3.45561 17.5314C3.74733 17.8314 4.143 18 4.55556 18H15.4444C15.857 18 16.2527 17.8314 16.5444 17.5314C16.8361 17.2313 17 16.8243 17 16.4V5.2C17 4.312 16.3 3.6 15.4444 3.6H14.6667V2H13.1111ZM13.8889 10.8H10V14.8H13.8889V10.8Z" fill="#A1A4A6"/>
			</svg></div>`;
			html += "<div class='line_description' style='width:" + (scheduler.xy.map_description_width - scheduler.xy.map_icon_width) + "px;'>" + scheduler.templates.map_text(ev.start_date, ev.end_date, ev) + "</div></div>"; // -25 = icon size 20 and padding 5
		}
		html += "<div class='dhx_v_border' style="+(scheduler.config.rtl ? "'right: " : "'left: ") + (scheduler.xy.map_date_width - 1) + "px;'></div><div class='dhx_v_border_description'></div></div>";

		//render html
		scheduler._els["dhx_cal_data"][0].scrollTop = 0; //fix flickering in FF
		scheduler._els["dhx_cal_data"][0].innerHTML = html;
	//	scheduler._els["dhx_cal_data"][0].style.width = (scheduler.xy.map_date_width + scheduler.xy.map_description_width + 1) + 'px';

		let t = scheduler._els["dhx_cal_data"][0].firstChild.childNodes;

		let dateElement = scheduler._getNavDateElement();
		if(dateElement){
			dateElement.innerHTML=scheduler.templates[scheduler._mode + "_date"](scheduler._min_date, scheduler._max_date, scheduler._mode);
		}

		scheduler._rendered = [];
		for (let i = 0; i < t.length - 2; i++) {
			scheduler._rendered[i] = t[i];
		}
	}
	function set_full_view(mode) {
		if (mode) {
			const l = scheduler.locale.labels;
			scheduler._els["dhx_cal_header"][0].innerHTML = "<div class='dhx_map_head' style='width: " + 
																(scheduler.xy.map_date_width + scheduler.xy.map_description_width + 2) + 
																"px;' ><div class='headline_date' style='width: " + 
																scheduler.xy.map_date_width + "px;'>" + l.date + 
																"</div><div class='headline_description' style='width: " + 
																scheduler.xy.map_description_width + "px;'>" + l.description + "</div></div>";
			scheduler._table_view = true;
			scheduler.set_sizes();
		}
	}
	
	scheduler.attachEvent("onLocationError", function (id){
		alert("Location can't be found");
		return google.maps.LatLng(51.477840, -0.001492); 
		//the coordinates of the Greenwich Royal Observatory
	})
	let _updateEventLocation = async function(event) {
		if (mapAdapter){
			const coordinates = await mapAdapter.resolveAddress(event.event_location);
			if (coordinates.lat && coordinates.lng) {
				event.lat = +coordinates.lat;
				event.lng = +coordinates.lng;
			} else {
				scheduler.callEvent("onLocationError", [event.id]);
				event.lng = scheduler.config.map_settings.error_position.lng;
				event.lat = scheduler.config.map_settings.error_position.lat;
			}
			scheduler._latLngUpdate = true;
			scheduler.callEvent("onEventChanged", [event.id, event]);
		}
	};
	let _delay = function(method, object, params, delay) {
		setTimeout(function() {
			if(scheduler.$destroyed){
				return true;
			}
			let ret = method.apply(object, params);
			method = object = params = null;
			return ret;
		}, delay || 1);
	};
	scheduler._event_resolve_delay = 1500;
	scheduler.attachEvent("onEventLoading", function(event) {
		if (event.lat && event.lng) {
			event.lat = +event.lat;
			event.lng = +event.lng;
		}
		if (scheduler.config.map_settings.resolve_event_location && event.event_location && !event.lat && !event.lng) { // don't delete !event.lat && !event.lng as location could change
			scheduler._event_resolve_delay += 1500;
			_delay(_updateEventLocation, this, [event], scheduler._event_resolve_delay);
		}
		return true;
	});
})

}