class EventHost {
	constructor(){
		this._silent_mode = false;
		this.listeners = {};
	}
	_silentStart(){
		this._silent_mode = true;
	}
	_silentEnd() {
		this._silent_mode = false;
	}
}

const createEventStorage = function(obj) {
	let handlers = {};
	let index = 0;
	const eventStorage = function(){
		let combinedResult = true;
		for(const i in handlers){
			const handlerResult = handlers[i].apply(obj, arguments);
			combinedResult=combinedResult && handlerResult;
		}
		return combinedResult;
	};
	eventStorage.addEvent=function(handler, settings){
		if (typeof (handler) == "function"){
			let handlerId;
			if(settings && settings.id){
				handlerId = settings.id;
			}else{
				handlerId = index;
				index++;
			}

			if(settings && settings.once){
				const originalHandler = handler;
				handler = function(){
					originalHandler();
					eventStorage.removeEvent(handlerId);
				};
			}

			handlers[handlerId] = handler;
			return handlerId;
		}
		return false;
	};
	eventStorage.removeEvent=function(id){
		delete handlers[id];
	};

	eventStorage.clear = function(){
		handlers = {};
	};

	return eventStorage;
};

function makeEventable(obj){

	const eventHost = new EventHost();
	obj.attachEvent=function(eventName, handler, settings){
		eventName = 'ev_'+eventName.toLowerCase();
		if (!eventHost.listeners[eventName]){
			eventHost.listeners[eventName] = createEventStorage(this);
		}

		if(settings && settings.thisObject){
			handler = handler.bind(settings.thisObject);
		}

		const innerId = eventHost.listeners[eventName].addEvent(handler, settings);

		let handlerId = (eventName+':'+innerId); //return ID (ev_eventname:1)
		if(settings && settings.id){
			handlerId = settings.id;
		}
		return handlerId;
	};

	obj.attachAll = function(callback){
		this.attachEvent('listen_all', callback);
	};

	obj.callEvent=function(name, eventArguments){
		if (eventHost._silent_mode) return true;

		const handlerName = 'ev_'+name.toLowerCase();

		const listeners = eventHost.listeners;
		if (listeners['ev_listen_all']){
			listeners['ev_listen_all'].apply(this, [name].concat(eventArguments));
		}

		if (listeners[handlerName])
			return listeners[handlerName].apply(this, eventArguments);
		return true;
	};

	obj.checkEvent=function(name){
		const listeners = eventHost.listeners;
		return (!!listeners['ev_'+name.toLowerCase()]);
	};

	obj.detachEvent=function(id){
		if (id){
			let listeners = eventHost.listeners;
			for(const i in listeners){
				listeners[i].removeEvent(id); //remove event
			}

			const list = id.split(':');//get EventName and ID
			listeners = eventHost.listeners;
			if(list.length === 2){
				const eventName = list[0];
				const eventId = list[1];
				if(listeners[eventName]){
					listeners[eventName].removeEvent(eventId); //remove event
				}
			}
		}
	};
	obj.detachAllEvents = function(){
		for (const name in eventHost.listeners) {
			eventHost.listeners[name].clear();
		}
	};

}

export default makeEventable;