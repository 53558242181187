import { TooltipManager } from "./tooltipManager";

export default function (scheduler: any) {

	scheduler.config.tooltip_timeout = 30;
	scheduler.config.tooltip_offset_y = 20;
	scheduler.config.tooltip_offset_x = 10;
	scheduler.config.tooltip_hide_timeout = 30;

	const tooltipManager = new TooltipManager(scheduler);

	scheduler.ext.tooltips = tooltipManager;

	scheduler.attachEvent("onSchedulerReady", function () {

		tooltipManager.tooltipFor({
			selector: "[" + scheduler.config.event_attribute + "]",
			html: (event: MouseEvent) => {
				if (scheduler._mobile && !scheduler.config.touch_tooltip) {
					return;
				}

				const targetEventId = scheduler._locate_event(event.target);
				if (scheduler.getEvent(targetEventId)) {
					const event = scheduler.getEvent(targetEventId);
					return scheduler.templates.tooltip_text(event.start_date, event.end_date, event);
				}
				return null;
			},
			global: false
		});
	});

	scheduler.attachEvent("onDestroy", function () {
		tooltipManager.destructor();
	});

	scheduler.attachEvent("onLightbox", function () {
		tooltipManager.hideTooltip();
	});

	scheduler.attachEvent("onBeforeDrag", function() {
		// GS-2377: don't hide tooltip on mobile device when ontouchstart event occurs
		if (scheduler._mobile && scheduler.config.touch_tooltip) {
			return true;
		}
		tooltipManager.hideTooltip();
		return true;
	});
	scheduler.attachEvent("onEventDeleted", function() {
		tooltipManager.hideTooltip();
		return true;
	});

}