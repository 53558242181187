export default function extend(scheduler) {


scheduler._addThemeClass = function(){
	document.documentElement.setAttribute("data-scheduler-theme", scheduler.skin);
};

scheduler._skin_settings = {
	fix_tab_position: [1,0],
	use_select_menu_space: [1,0],
	wide_form: [1,0],

	hour_size_px: [44,42],
	displayed_event_color: ["#ff4a4a", "ffc5ab"],
	displayed_event_text_color: ["#ffef80", "7e2727"]
};

scheduler._skin_xy = {
	lightbox_additional_height: [90,50],
	nav_height: [59,22],
	bar_height: [24,20]
};

// material skin uses a different box sizing model than other skins, and also requires some post-processing.
// In order to render events correctly, we need to know which box sizing model is used
// We can detect it by styles applied, taking into account that styles may be loaded after scheduler is rendered

scheduler._is_material_skin = function(){
	if(!scheduler.skin){
		return checkIfMaterialSkin();
	}else{
		return ((scheduler.skin + "").indexOf("material") > -1);
	}
};

function themeNameFromFile(){
	var links = document.getElementsByTagName("link");
	for (var i = 0; i < links.length; i++) {
		var res = links[i].href.match("dhtmlxscheduler_([a-z]+).css");
		if (res){
			return res[1];
		}
	}
}

scheduler._build_skin_info = function() {
	monitorThemeChange();
	const styles = getComputedStyle(this.$container);
	const themeVar = styles.getPropertyValue("--dhx-scheduler-theme");

	let isCssVarTheme = !!themeVar;
	let themeName;
	let cssValues = {};
	let oldMaterialTheme = false;

	if(isCssVarTheme){
		themeName = themeVar;
		for(let i in scheduler.xy){
			cssValues[i] = styles.getPropertyValue(`--dhx-scheduler-xy-${i}`);
		}

		cssValues.hour_size_px = styles.getPropertyValue(`--dhx-scheduler-config-hour_size_px`);
		cssValues.wide_form = styles.getPropertyValue(`--dhx-scheduler-config-form_wide`);

	}else{
		themeName = themeNameFromFile();
		oldMaterialTheme = scheduler._is_material_skin();
	}


	scheduler._theme_info = {
		theme: themeName,
		cssVarTheme: isCssVarTheme,
		oldMaterialTheme: oldMaterialTheme,
		values: cssValues
	};

	if(scheduler._theme_info.cssVarTheme){
		const themeVariables = this._theme_info.values;
		for(let i in scheduler.xy){
			if(!isNaN(parseInt(themeVariables[i]))){
				scheduler.xy[i] = parseInt(themeVariables[i]);
			}
		}
	}
};

var calculatedMaterial;
function checkIfMaterialSkin(){
	if(calculatedMaterial === undefined){
		var probe = document.createElement("div");
		probe.style.position = "absolute";
		probe.style.left = "-9999px";
		probe.style.top = "-9999px";
		probe.innerHTML = "<div class='dhx_cal_container'>" +
			"<div class='dhx_cal_scale_placeholder'>" +
			"</div>"+
		"<div>";
		document.body.appendChild(probe);
		var styles = window.getComputedStyle(probe.querySelector(".dhx_cal_scale_placeholder"));
		var position = styles.getPropertyValue('position');
		if(position === "absolute"){
			// page has skins for placeholder element from material skin
			calculatedMaterial = true;
		}else{
			calculatedMaterial = false;
		}

		setTimeout(function(){
			calculatedMaterial = null;
			if(probe && probe.parentNode){
				probe.parentNode.removeChild(probe);
			}
		}, 500);
	}
	return calculatedMaterial;
}

var cachedBorderBoxValue;
function checkIfBorderBoxStyling(){
	if(scheduler._is_material_skin()){
		return true;
	}else{
		if(cachedBorderBoxValue === undefined){
			var probe = document.createElement("div");
			probe.style.position = "absolute";
			probe.style.left = "-9999px";
			probe.style.top = "-9999px";
			probe.innerHTML = "<div class='dhx_cal_container'>" +
				"<div class='dhx_cal_data'>" +
					"<div class='dhx_cal_event'><div class='dhx_body'></div>"+
				"</div>"+
			"<div>";

			document.body.appendChild(probe);
			var styles = window.getComputedStyle(probe.querySelector(".dhx_body"));
			var boxSizing = styles.getPropertyValue('box-sizing');
			document.body.removeChild(probe);
			cachedBorderBoxValue = !!(boxSizing === "border-box");

			if(!cachedBorderBoxValue){
				setTimeout(function(){
					cachedBorderBoxValue = undefined;
				}, 1000);// recalculate in case scheduler initialized before skin is loaded
			}
		}else{
			return cachedBorderBoxValue;
		}
	}
}

function refreshAfterLoad(){
	if(scheduler._is_material_skin() || scheduler._border_box_events()){
		return;
	}

	var oldStyling = cachedBorderBoxValue;
	cachedBorderBoxValue = undefined;
	calculatedMaterial = undefined;
	var newStyling = checkIfBorderBoxStyling();

	// if box styling model changed - means scheduler was rendered before stylesheet was loaded or parsed inline
	// repaint scheduler in order to apply new styles
	if(oldStyling !== newStyling && scheduler.$container && scheduler.getState().mode){
		scheduler.setCurrentView();
	}
}
scheduler.event(window, 'DOMContentLoaded', refreshAfterLoad);
scheduler.event(window, 'load', refreshAfterLoad);

scheduler._border_box_events = function(){
	return checkIfBorderBoxStyling();
};

scheduler._configure = function(col, data, skin){
	for (var key in data)
		if (typeof col[key] == "undefined")
			col[key] = data[key][skin];
};

scheduler.setSkin = function(value){
	this.skin = value;
	scheduler._addThemeClass();
	if(scheduler.$container){
		this._skin_init();
		this.render();
	}
	
	//
};

function flatSkinHourScale(date){
	var min = date.getMinutes();
	min = min < 10 ? "0"+min : min;
	var html = "<span class='dhx_scale_h'>"+ date.getHours() +"</span>"+
		"<span class='dhx_scale_m'>&nbsp;"+ min +"</span>";
	return html;
}

let monitorIntervalId = null;
function monitorThemeChange(){
	const container = scheduler.$container;
	
	clearInterval(monitorIntervalId);
	if(container){
		monitorIntervalId = setInterval(() => {
			const csstheme = getComputedStyle(container).getPropertyValue('--dhx-scheduler-theme');
			if(csstheme && csstheme !== scheduler.skin){
				scheduler.setSkin(csstheme);
			}

		}, 100);
	}
}
scheduler.attachEvent("onDestroy", function(){
	clearInterval(monitorIntervalId);
});

scheduler._skin_init = function(){
	this._build_skin_info();

	if (!this.skin){
		this.skin = this._theme_info.theme;
	}
	scheduler._addThemeClass();
	if (scheduler.skin === "flat"){
		// scheduler.xy.scale_height = 35;
		scheduler.templates.hour_scale = flatSkinHourScale;
	}else if (scheduler.templates.hour_scale === flatSkinHourScale){
		scheduler.templates.hour_scale = scheduler.date.date_to_str(scheduler.config.hour_date);
	}



	//scheduler._lightbox_template="<div class='dhx_cal_ltitle'><span class='dhx_mark'>&nbsp;</span><span class='dhx_time'></span><span class='dhx_title'></span><div class='dhx_close_icon'></div></div><div class='dhx_cal_larea'></div>";
	scheduler.attachEvent("onTemplatesReady", function() {

		var date_to_str = scheduler.date.date_to_str("%d");
		if(!scheduler.templates._old_month_day){
			scheduler.templates._old_month_day = scheduler.templates.month_day;
		}
		var old_month_day = scheduler.templates._old_month_day;
		scheduler.templates.month_day = function(date) {
			if (this._mode == "month") {
				var label = date_to_str(date);
				if (date.getDate() == 1) {
					label = scheduler.locale.date.month_full[date.getMonth()] + " " + label;
				}
				if (+date == +scheduler.date.date_part(this._currentDate())) {
					label = scheduler.locale.labels.dhx_cal_today_button + " " + label;
				}
				return label;
			} else {
				return old_month_day.call(this, date);
			}
		};

		// tab classes
		if(scheduler.config.fix_tab_position){
			const tabs = scheduler._els["dhx_cal_navline"][0].querySelectorAll('[data-tab]');
			tabs.forEach((tab) => {
				const view = tab.getAttribute("data-tab") || tab.getAttribute("name");

				switch (view) {
					case "day":
					case "day_tab":
						tab.classList.add("dhx_cal_tab_first");
						tab.classList.add("dhx_cal_tab_segmented");
						break;
					case "week":
					case "week_tab":
						tab.classList.add("dhx_cal_tab_segmented");
						break;
					case "month":
					case "month_tab":
						tab.classList.add("dhx_cal_tab_last");
						tab.classList.add("dhx_cal_tab_segmented");
						break;
					default:
						tab.classList.add("dhx_cal_tab_standalone");
						break;
				}
			});

			reorderTabs(scheduler._els["dhx_cal_navline"][0]);

		}
	}, {once: true});

	function reorderTabs(container) {
		// segmented tabs should go first

		if(scheduler.config.header){
			return;
			// don't reorder tabs if header is defined via config
		}
		const tabs = Array.from(container.querySelectorAll('.dhx_cal_tab'));
	
		// segmented tabs
		const order = ['day', 'week', 'month'];
	
		const specialTabs = order.map(tabName => 
			tabs.find(tab => tab.getAttribute('data-tab') === tabName)
		).filter(tab => tab !== undefined);
	
		let firstTab = tabs.length > 0 ? tabs[0] : null;

		specialTabs.reverse().forEach(tab => {
			container.insertBefore(tab, firstTab);
			firstTab = tab;
		});
	}

	// scheduler._skin_init = function(){
	// 	this._build_skin_info();
	// 	this.skin = this._theme_info.theme;
	// };
};



}