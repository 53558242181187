import { IMapAdapter, ICalendarEvent, IMapSettings, IMapPosition} from "./types";

declare let mapboxgl: any;
declare let MapboxGeocoder: any;

export class MapboxAdapter implements IMapAdapter {
	private map: any;
	private scheduler: any;
	private settings: IMapSettings;
	private _markers: any[];
	constructor(scheduler: any) {
		this.map = null;
		this._markers = [];
		this.scheduler = scheduler;
	}
	onEventClick(event: ICalendarEvent): void {
		if (this._markers && this._markers.length > 0) {
			for (let i = 0; i <  this._markers.length; i++) {
                const popup = this._markers[i].marker.getPopup();
                if (popup.isOpen()){
                    popup.remove();
                }
				if (event.id ==  this._markers[i].event.id) {
					this._markers[i].marker.togglePopup();
					if (event.lat && event.lng) {
						this.setView(event.lat, event.lng, this.settings.zoom_after_resolve || this.settings.initial_zoom);
					} else {
						this.setView(this.settings.error_position.lat, this.settings.error_position.lng, this.settings.zoom_after_resolve || this.settings.initial_zoom);
					}
				}	
			}
		} 
	}

	initialize(container: HTMLElement, options: IMapSettings): void {
        let scheduler = this.scheduler;

		mapboxgl.accessToken = options.accessToken;
        const map = new mapboxgl.Map({
            container: container, 
            center: [options.initial_position.lng, options.initial_position.lat], 
            zoom:  options.initial_zoom + 1, // problems with coordinates if zoom = 1
        });
        map.on("dblclick",async function(e){
			let response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${e.lngLat.lng},${ e.lngLat.lat}.json?access_token=${options.accessToken}`).then(response => response.json());
			if (response.features){
				let address = response.features[0].place_name;
				scheduler.addEventNow({
					lat: e.lngLat.lat,
					lng: e.lngLat.lng,
					event_location: address,
					start_date: scheduler.getState().date,
					end_date: scheduler.date.add(scheduler.getState().date, scheduler.config.time_step, "minute")
				});
			} else {
				console.error("unable recieve a position of the event");
			}
		});
        this.map = map;
        this.settings = options;
	}

	destroy(container: HTMLElement): void {
		this.map.remove();
        while (container.firstChild) {
			container.firstChild.remove();
		}
        container.innerHTML = "";
	}

	addEventMarker(event: ICalendarEvent): void {
        let config = [
			event.lng,
			event.lat
		]
		
		if (!event.lat || !event.lng) {
			config = [this.settings.error_position.lng, this.settings.error_position.lat];
		} 

		const popup = new mapboxgl.Popup({ offset: 25, focusAfterOpen: false})
            .setMaxWidth(`${this.settings.info_window_max_width}px`) 
            .setHTML(this.scheduler.templates.map_info_content(event));
        const marker = new mapboxgl.Marker()
            .setLngLat(config)
            .setPopup(popup)
            .addTo(this.map);
        const markerInfo = {event, marker};    
        this._markers.push(markerInfo);
	}

	removeEventMarker(eventId: string): void {
		for (let i = 0; i < this._markers.length; i++) {
			if (eventId == this._markers[i].event.id) {
				this._markers[i].marker.remove();
                this._markers.splice(i,1);
				i--;
			}
		}
	}

	updateEventMarker(event: ICalendarEvent): void {
		for (let i = 0; i < this._markers.length; i++) {
			if(this._markers[i].event.id == event.id) {
				this._markers[i].event = event;
				if (!event.lat || !event.lng){
					this._markers[i].marker.setLngLat([this.settings.error_position.lng, this.settings.error_position.lat]);
				} else {
					this._markers[i].marker.setLngLat([event.lng, event.lat]);
				}
			}
		}
	}

	clearEventMarkers(): void {
		for (let i = 0; i <this._markers.length; i++) {
			this._markers[i].marker.remove();
		}
		this._markers = [];
	}

	setView(latitude: number, longitude: number, zoom: number): void {
		this.map.setCenter([longitude, latitude]);
        this.map.setZoom(zoom);
	}

	async resolveAddress(string: string): Promise<IMapPosition> {
		let response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${string}.json?access_token=${this.settings.accessToken}`).then(response => response.json());
		let position:any = {};
		if (response && response.features.length) {
			position.lng = response.features[0].center[0];
			position.lat = response.features[0].center[1];
		} else {
			console.error(`Unable recieve a position of the event's location: ${string}`);
		}
		return position;
	}
}