import global from "../global";

export default function extend(scheduler) {
/*
if (global.dataProcessor && !dataProcessor.prototype.init_original){
	dataProcessor.prototype.init_original=dataProcessor.prototype.init;
	dataProcessor.prototype.init=function(obj){
		this.init_original(obj);
		obj._dataprocessor=this;

		this.setTransactionMode("POST",true);
		this.serverProcessor+=(this.serverProcessor.indexOf("?")!=-1?"&":"?")+"editing=true";
	};
}*/

scheduler.attachEvent("onSchedulerReady", function(){
	if(typeof dhtmlxError !== "undefined") {
		window.dhtmlxError.catchError("LoadXML", function(a, b, c){
			var message = c[0].responseText;

			switch (scheduler.config.ajax_error){
				case "alert":
					global.alert(message);
					break;
				case "console":
					global.console.log(message);
					break;
				default:
					break;
			}
		});
	}
});


}