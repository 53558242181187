import active_links from "./active_links";
import agenda_legacy from "./agenda_legacy_view";
import agenda_view from "./agenda";
import all_timed from "./all_timed";
import collision from "./collision";
import container_autoresize from "./container_autoresize";
import cookie from "./cookie";
import daytimeline_restricted from "./restricted_extensions/daytimeline";
import drag_between_restricted from "./restricted_extensions/drag_between";
import editors from "./editors";
import expand from "./expand";
import grid_view from "./restricted_extensions/grid_view";
import html_templates from "./html_templates";
import key_nav from "./key_nav";
import layer from "./layer";
import limit from "./limit";
import map_view from "./map_view";
import minical from "./minical";
import monthheight from "./monthheight";
import multisection_restricted from "./restricted_extensions/multisection";
import multiselect from "./multiselect";
import multisource from "./multisource";
import mvc from "./mvc";
import outerdrag from "./outerdrag";
import pdf from "./pdf";
import quick_info from "./quick_info";
import readonly from "./readonly";
import recurring from "./recurring";
import recurring_legacy from "./recurring_legacy";
import serialize from "./serialize";
import timeline_restricted from "./restricted_extensions/timeline";
import tooltip from "./tooltip";
import treetimeline_restricted from "./restricted_extensions/treetimeline";
import units_restricted from "./restricted_extensions/units";
import url from "./url";
import week_agenda_restricted from "./restricted_extensions/week_agenda";
import wp from "./wp";
import year_view from "./year_view";
import export_api from "./export_api";

export default {
	active_links,
	agenda_legacy,
	agenda_view,
	all_timed,
	collision,
	container_autoresize,
	cookie,
	daytimeline: daytimeline_restricted,
	drag_between: drag_between_restricted,
	editors,
	expand,
	export_api,
	grid_view,
	html_templates,
	key_nav,
	layer,
	limit,
	map_view,
	minical,
	monthheight,
	multisection: multisection_restricted,
	multiselect,
	multisource,
	mvc,
	outerdrag,
	pdf,
	quick_info,
	readonly,
	recurring,
	recurring_legacy,
	serialize,
	timeline: timeline_restricted,
	tooltip,
	treetimeline: treetimeline_restricted,
	units: units_restricted,
	url,
	week_agenda: week_agenda_restricted,
	wp,
	year_view
};