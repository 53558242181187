import serialize from "../common/url_serialize.js";

export default function extend(scheduler) {

scheduler.Promise = window.Promise; 

function createConfig(method, args) {
	var result = {
		method: method
	};

	if (args.length === 0) {
		throw new Error("Arguments list of query is wrong.");
	}
	if (args.length === 1) {
		if (typeof args[0] === "string") {
			result.url = args[0];
			result.async = true;
		} else {
			result.url = args[0].url;
			result.async = (args[0].async || true);
			result.callback = args[0].callback;
			result.headers = args[0].headers;
		}
		if (method === "POST" || "PUT") {
			if (args[0].data) {
				if (typeof args[0].data !== "string") {
					result.data = serialize(args[0].data);
				} else {
					result.data = args[0].data;
				}
			} else {
				result.data = "";
			}
		}
		return result;
	}

	result.url = args[0];
	switch(method) {
		case "GET":
		case "DELETE":
			result.callback = args[1];
			result.headers = args[2];
		break;
		case "POST":
		case "PUT":
			if (args[1]) {
				if (typeof args[1] !== "string") {
					result.data = serialize(args[1]);
				} else {
					result.data = args[1];
				}
			} else {
				result.data = "";
			}
			result.callback = args[2];
			result.headers = args[3];
		break;
	}
	return result;
}
scheduler.ajax = {
	// if false - dhxr param will added to prevent caching on client side (default),
	// if true - do not add extra params
	cache: true,

	// default method for load/loadStruct, post/get allowed
	method: "get",

	serializeRequestParams: serialize,
	parse: function(data) {
		if (typeof data !== "string") return data;

		var obj;
		data = data.replace(/^[\s]+/,"");
		if (typeof DOMParser !== "undefined" && !scheduler.$env.isIE) { // ff,ie9
			obj = (new DOMParser()).parseFromString(data, "text/xml");
		} else if (typeof window.ActiveXObject !== "undefined") {
			obj = new window.ActiveXObject("Microsoft.XMLDOM");
			obj.async = "false";
			obj.loadXML(data);
		}
		return obj;
	},
	xmltop: function(tagname, xhr, obj) {
		if (typeof xhr.status == "undefined" || xhr.status < 400) {
			var xml = (!xhr.responseXML) ? this.parse(xhr.responseText || xhr) : (xhr.responseXML || xhr);
			if (xml && xml.documentElement !== null && !xml.getElementsByTagName("parsererror").length) {
				return xml.getElementsByTagName(tagname)[0];
			}
		}
		if (obj !== -1) scheduler.callEvent("onLoadXMLError",["Incorrect XML", arguments[1], obj]);
		return document.createElement("DIV");
	},
	xpath: function(xpathExp, docObj) {
		if (!docObj.nodeName) docObj = docObj.responseXML || docObj;
		if (scheduler.$env.isIE) {
			return docObj.selectNodes(xpathExp)||[];
		} else {
			var rows = [];
			var first;
			var col = (docObj.ownerDocument||docObj).evaluate(xpathExp, docObj, null, XPathResult.ANY_TYPE, null);

			while (true){
				first = col.iterateNext();
				if(first){
					rows.push(first);
				}else{
					break;
				}
			}
			return rows;
		}
	},
	query: function(config) {
		return this._call(
			(config.method || "GET"),
			config.url,
			config.data || "",
			(config.async || true),
			config.callback,
			config.headers
		);
	},
	get: function(url, onLoad, headers) {
		var config = createConfig("GET", arguments);
		return this.query(config);
	},
	getSync: function(url, headers) {
		var config = createConfig("GET", arguments);
		config.async = false;
		return this.query(config);
	},
	put: function(url, postData, onLoad, headers) {
		var config = createConfig("PUT", arguments);
		return this.query(config);
	},
	del: function(url, onLoad, headers) {
		/**
		 * https://tools.ietf.org/html/rfc7231#section-4.3.5
		 * A payload within a DELETE request message has no defined semantics;
		 * sending a payload body on a DELETE request might cause some existing
		 * implementations to reject the request.
		 */
		var config = createConfig("DELETE", arguments);
		return this.query(config);
	},
	post: function(url, postData, onLoad, headers) {
		if (arguments.length == 1) {
			postData = "";
		} else if (arguments.length == 2 && typeof(postData) == "function") {
			onLoad = postData;
			postData = "";
		}
		var config = createConfig("POST", arguments);
		return this.query(config);
	},
	postSync: function(url, postData, headers) {
		postData = (postData === null ? "" : String(postData));

		var config = createConfig("POST", arguments);
		config.async = false;
		return this.query(config);
	},
	_call: function(method, url, postData, async, onLoad, headers) {
		return new scheduler.Promise((function(resolve, reject) {
			var t = (typeof XMLHttpRequest !== undefined && !scheduler.$env.isIE ? new XMLHttpRequest() : new window.ActiveXObject("Microsoft.XMLHTTP"));
			var isQt = (navigator.userAgent.match(/AppleWebKit/) !== null && navigator.userAgent.match(/Qt/) !== null && navigator.userAgent.match(/Safari/) !== null);

			if (!!async) {
				t.addEventListener("readystatechange", function() {
					if ((t.readyState == 4) || (isQt && t.readyState == 3)) { // what for long response and status 404?
						if (t.status != 200 || t.responseText === "")
							if (!scheduler.callEvent("onAjaxError", [t])) return;

						setTimeout(function() {
							if (typeof(onLoad) == "function") {
								onLoad.apply(window, [{xmlDoc:t, filePath:url}]); // dhtmlx-compat, response.xmlDoc.responseXML/responseText
							}
							resolve(t);
							if (typeof(onLoad) == "function") {
								onLoad = null;
								t = null;
							}
						}, 0);
					}
				});
			}

			if (method == "GET" && !this.cache) {
				url += (url.indexOf("?")>=0?"&":"?")+"dhxr"+new Date().getTime()+"=1";
			}

			t.open(method, url, async);

			if (headers){
				for (var key in headers)
					t.setRequestHeader(key, headers[key]);
			} else if (method.toUpperCase() == "POST" || method == "PUT" || method == "DELETE") {
				t.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
			} else if (method == "GET") {
				postData = null;
			}

			t.setRequestHeader("X-Requested-With", "XMLHttpRequest");

			t.send(postData);

			if (!async) return {xmlDoc:t, filePath:url}; // dhtmlx-compat, response.xmlDoc.responseXML/responseText
		}.bind(this)));
	},
	urlSeparator: function(str){
		if (str.indexOf("?") != -1)
			return "&";
		else
			return "?";
	}
};

scheduler.$ajax = scheduler.ajax; // for old

}