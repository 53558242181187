export default function(scheduler){

scheduler.config.limit_start = null;
scheduler.config.limit_end   = null;
scheduler.config.limit_view  = false;
scheduler.config.check_limits = true;

scheduler._temp_limit_scope = function(){
	var before = null;
	var dhx_time_block = "dhx_time_block";

	scheduler.attachEvent("onBeforeViewChange",function(om,od,nm,nd){

		function isBlocked(date, mode){
			var limit_start = scheduler.config.limit_start,
				limit_end = scheduler.config.limit_end,
				date_end =  scheduler.date.add(date,1,mode);

			return (date.valueOf() > limit_end.valueOf() || date_end <= limit_start.valueOf());
		}

		if (scheduler.config.limit_view){
			nd = nd||od; nm = nm||om;
			if (isBlocked(nd, nm) && !(od.valueOf() == nd.valueOf())){
				setTimeout(function(){
					if(scheduler.$destroyed){
						return true;
					}
					var resetDate = !isBlocked(od, nm) ? od : scheduler.config.limit_start;

					scheduler.setCurrentView(!isBlocked(resetDate, nm) ? resetDate : null, nm);
				},1);
				return false;
			}
		}
		return true;
	});
	scheduler.attachEvent("onMouseDown", function(classname) {
		return !(classname == dhx_time_block);
	});
	scheduler.attachEvent("onBeforeDrag",function(id){
		if (!id) return true;
		return scheduler.checkLimitViolation(scheduler.getEvent(id));
	});
	scheduler.attachEvent("onClick", function (event_id, native_event_object){
		return scheduler.checkLimitViolation(scheduler.getEvent(event_id));
    });
	scheduler.attachEvent("onBeforeLightbox",function(id){

		var ev = scheduler.getEvent(id);
		before = [ev.start_date, ev.end_date];
		return scheduler.checkLimitViolation(ev);
	});
	scheduler.attachEvent("onEventSave", function(id, data, is_new_event) {

		//lightbox may not have 'time' section
		if(!(data.start_date && data.end_date)){
			var ev = scheduler.getEvent(id);
			data.start_date = new Date(ev.start_date);
			data.end_date = new Date(ev.end_date);
		}

		if(data.rec_type){
			//_roll_back_dates modifies start_date of recurring event, need to check limits after modification
			// use a copy to keep original event unchanged
			var data_copy = scheduler._lame_clone(data);
			scheduler._roll_back_dates(data_copy);
			return scheduler.checkLimitViolation(data_copy);
		}
		return scheduler.checkLimitViolation(data);
	});
	scheduler.attachEvent("onEventAdded",function(id){
		if (!id) return true;
		var ev = scheduler.getEvent(id);
		if (!scheduler.checkLimitViolation(ev) && scheduler.config.limit_start && scheduler.config.limit_end) {
			//if newly created event is outside of limited time - crop it, leaving only allowed time
			if (ev.start_date < scheduler.config.limit_start) {
				ev.start_date = new Date(scheduler.config.limit_start);
			}
			if (ev.start_date.valueOf() >= scheduler.config.limit_end.valueOf()) {
				ev.start_date = this.date.add(scheduler.config.limit_end, -1, "day");
			}
			if (ev.end_date < scheduler.config.limit_start) {
				ev.end_date = new Date(scheduler.config.limit_start);
			}
			if (ev.end_date.valueOf() >= scheduler.config.limit_end.valueOf()) {
				ev.end_date = this.date.add(scheduler.config.limit_end, -1, "day");
			}
			if (ev.start_date.valueOf() >= ev.end_date.valueOf()) {
				ev.end_date = this.date.add(ev.start_date, (this.config.event_duration||this.config.time_step), "minute");
			}
			ev._timed=this.isOneDayEvent(ev);
		}
		return true;
	});
	scheduler.attachEvent("onEventChanged",function(id){
		if (!id) return true;
		var ev = scheduler.getEvent(id);
		if (!scheduler.checkLimitViolation(ev)){
			if (!before) return false;
			ev.start_date = before[0];
			ev.end_date = before[1];
			ev._timed=this.isOneDayEvent(ev);
		}
		return true;
	});
	scheduler.attachEvent("onBeforeEventChanged",function(ev, native_object, is_new){
		return scheduler.checkLimitViolation(ev);
	});
	scheduler.attachEvent("onBeforeEventCreated", function(ev) { // native event
		var start_date = scheduler.getActionData(ev).date;
		var event = {
			_timed: true,
			start_date: start_date,
			end_date: scheduler.date.add(start_date, scheduler.config.time_step, "minute")
		};
		return scheduler.checkLimitViolation(event);
	});

	scheduler.attachEvent("onViewChange", function(){
		scheduler._mark_now();
	});

	scheduler.attachEvent("onAfterSchedulerResize", function(){
		window.setTimeout(function(){ 
			if(scheduler.$destroyed){
				return true;
			}
			scheduler._mark_now(); 
		}, 1);
		return true;
	});

	scheduler.attachEvent("onTemplatesReady", function() {
		scheduler._mark_now_timer = window.setInterval(function() {
			if(!scheduler._is_initialized())
				return;
			scheduler._mark_now();
		}, 60000);
	});

	scheduler.attachEvent("onDestroy", function(){
		clearInterval(scheduler._mark_now_timer);
	});

};
scheduler._temp_limit_scope();


}