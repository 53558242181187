export default function(scheduler){

scheduler.$keyboardNavigation.dispatcher = {
	isActive: false,
	activeNode: null,
	globalNode: new scheduler.$keyboardNavigation.SchedulerNode(),

	keepScrollPosition: function (callback) {
		var top, left;

		var scrollable = scheduler.$container.querySelector(".dhx_timeline_scrollable_data");
		if(!scrollable){
			scrollable = scheduler.$container.querySelector(".dhx_cal_data");
		}

		if(scrollable){
			top = scrollable.scrollTop;
			left = scrollable.scrollLeft;
		}

		callback();

		if(scrollable){
			scrollable.scrollTop = top;
			scrollable.scrollLeft = left;
		}
	},
	enable: function(){
		if(!scheduler.$container){
			// do nothing if not initialized
			return;
		}

		this.isActive = true;
		var self = this;
		this.keepScrollPosition(function () {
			self.globalNode.enable();
			self.setActiveNode(self.getActiveNode());
		});
	},

	disable: function(){
		this.isActive = false;
		this.globalNode.disable();
	},

	isEnabled: function(){
		return !!this.isActive;
	},

	getDefaultNode: function(){
		return this.globalNode.getDefaultNode();
	},

	setDefaultNode: function() {
		this.setActiveNode(this.getDefaultNode());
	},

	getActiveNode: function(){
		var node = this.activeNode;
		if(node && !node.isValid()){
			node = node.fallback();
		}
		return node;
	},

	focusGlobalNode: function(){
		this.blurNode(this.globalNode);
		this.focusNode(this.globalNode);
	},

	setActiveNode: function(el){
		if(!el || !el.isValid())
			return;

		if(this.activeNode){
			if(this.activeNode.compareTo(el)){
				return;
			}
		}
		if(this.isEnabled()){
			this.blurNode(this.activeNode);
			this.activeNode = el;
			this.focusNode(this.activeNode);
		}
	},

	focusNode: function(el){
		if(el && el.focus){
			el.focus();
			if(el.getNode && document.activeElement != el.getNode()){
				this.setActiveNode(new scheduler.$keyboardNavigation.DataArea());
			}
		}
	},
	blurNode: function(el){
		if(el && el.blur){
			el.blur();
		}
	},

	getInlineEditor: function(id){
		var editor = scheduler.$container.querySelector(".dhx_cal_editor["+scheduler.config.event_attribute+"='"+id+"'] textarea");
		if(editor && editor.offsetWidth){
			// if exists and visible
			return editor;
		}
		return null;
	},

	keyDownHandler: function (e) {

		if(e.defaultPrevented){
			return;
		}

		var activeElement = this.getActiveNode();

		if(scheduler.$keyboardNavigation.isModal() &&
			!(activeElement && activeElement.container && scheduler.utils.dom.locateCss({target:activeElement.container}, "dhx_minical_popup", false)))
			return;

		if(scheduler.getState().editor_id && this.getInlineEditor(scheduler.getState().editor_id))
			return;

		if (!this.isEnabled())
			return;

		e = e || window.event;

		var schedulerNode = this.globalNode;

		var command = scheduler.$keyboardNavigation.shortcuts.getCommandFromEvent(e);

		if(!activeElement){
			this.setDefaultNode();
		}else if(activeElement.findHandler(command)){
			activeElement.doAction(command, e);
		}else if(schedulerNode.findHandler(command)){
			schedulerNode.doAction(command, e);
		}

	},

	_timeout: null,
	delay: function(callback, delay){

		clearTimeout(this._timeout);
		this._timeout = setTimeout(callback, delay || 1);

	}
};

}