import xmlLoaded from "./loaders/xml";
import jsonLoader from "./loaders/json";
import iCalLoader from "./loaders/ical";
import createSerializator from "../core/common/get_serializable_events";

export default function extend(scheduler) {

scheduler._loaded = {};
scheduler._load = function(url, from) {
	url = url || this._load_url;

	if(!url){
		//if scheduler.setLoadMode is called before scheduler.init, initial rendering will invoke data loading while url is undefined
		return;
	}

	url += (url.indexOf("?") == -1 ? "?" : "&") + "timeshift=" + (new Date()).getTimezoneOffset();
	if (this.config.prevent_cache)    url += "&uid=" + this.uid();
	var to;
	from = from || this._date;
	function ajaxCallback(response) {
		scheduler.on_load(response);
		scheduler.callEvent("onLoadEnd", []);
	}
	if (this._load_mode) {
		var lf = this.templates.load_format;

		from = this.date[this._load_mode + "_start"](new Date(from.valueOf()));
		while (from > this._min_date) from = this.date.add(from, -1, this._load_mode);
		to = from;

		var cache_line = true;
		while (to < this._max_date) {
			to = this.date.add(to, 1, this._load_mode);
			if (this._loaded[lf(from)] && cache_line)
				from = this.date.add(from, 1, this._load_mode); else cache_line = false;
		}

		var temp_to = to;
		do {
			to = temp_to;
			temp_to = this.date.add(to, -1, this._load_mode);
		} while (temp_to > from && this._loaded[lf(temp_to)]);

		if (to <= from)
			return false; //already loaded

		scheduler.ajax.get(url + "&from=" + lf(from) + "&to=" + lf(to), ajaxCallback);

		while (from < to) {
			this._loaded[lf(from)] = true;
			from = this.date.add(from, 1, this._load_mode);
		}
	} else {
		scheduler.ajax.get(url, ajaxCallback);
	}

	// TODO: remove onXLS
	this.callEvent("onXLS", []);
	this.callEvent("onLoadStart", []);
	return true;
};

scheduler._parsers = {};


xmlLoaded(scheduler);
jsonLoader(scheduler);
iCalLoader(scheduler);

scheduler.on_load = function (loader) {
	this.callEvent("onBeforeParse", []);

	var evs;
	var error = false;

	var foundParser = false;
	for(var i in this._parsers){
		var parser = this._parsers[i];
		if(parser.canParse(loader.xmlDoc.responseText, loader.xmlDoc)){
			try{
				var param = loader.xmlDoc.responseText;
				if(i === "xml"){
					param = loader;
				}
				evs = parser.parse(param);
				if(!evs){
					error = true;
				}
			}catch(e){
				error = true;
			}
			foundParser = true;
			break;
		}
	}

	if(!foundParser){
		if(this._process && this[this._process]){
			try{
				evs = this[this._process].parse(loader.xmlDoc.responseText);
			}catch (e){
				error = true;
			}
		}else{
			error = true;
		}
	}

	if(error || (loader.xmlDoc.status && loader.xmlDoc.status >= 400)){
		this.callEvent("onLoadError", [loader.xmlDoc]);
		evs = [];
	}

	this._process_loading(evs);

	// TODO: remove onXLE
	this.callEvent("onXLE", []);
	this.callEvent("onParse", []);
};
scheduler._process_loading = function(evs) {
	this._loading = true;
	this._not_render = true;
	for (var i = 0; i < evs.length; i++) {
		if (!this.callEvent("onEventLoading", [evs[i]])) continue;
		this.addEvent(evs[i]);
	}
	this._not_render = false;
	if (this._render_wait) this.render_view_data();

	this._loading = false;
	if (this._after_call) this._after_call();
	this._after_call = null;
};
scheduler._init_event = function(event) {
	event.text = (event.text || event._tagvalue) || "";
	event.start_date = scheduler._init_date(event.start_date);
	event.end_date = scheduler._init_date(event.end_date);
};

scheduler._init_date = function(date){
	if(!date)
		return null;
	if (typeof date == "string") {
		return scheduler._helpers.parseDate(date);
	}
	else return new Date(date);
};

const getSerializableData = createSerializator(scheduler);
scheduler.serialize = function(){
	const dataset = [];
	const allEvents = getSerializableData();
	for (var a in allEvents){
		
		const exportedEvent = {};
		var ev = allEvents[a];

		for(var key in ev){
			if (key.charAt(0) == "$" || key.charAt(0) == "_"){
				continue;
			}

			let exportValue;
			const rawValue = ev[key];
			if(scheduler.utils.isDate(rawValue)){
				exportValue = scheduler.defined(scheduler.templates.xml_format) ? scheduler.templates.xml_format(rawValue) : scheduler.templates.format_date(rawValue);
			}else{
				exportValue = rawValue;
			}
			
			exportedEvent[key] = exportValue;
		}

		dataset.push(exportedEvent);
	}
	return dataset;
};

/*
scheduler.json = {};
scheduler.json.parse = function(data) {

	var events = [];

	if (typeof data == "string") {
		data = JSON.parse(data);
	}
	if (Object.prototype.toString.call(data) === '[object Array]') {
		events = data;
	} else {
		if(data){
			if(data.events){
				events = data.events;
			}else if(data.data){
				events = data.data;
			}
		}
	}
	events = events || [];

	if (data.dhx_security) {
		if (window.dhtmlx) {
			window.dhtmlx.security_key = data.dhx_security;
		}
		scheduler.security_key = data.dhx_security;
	}

	var collections = (data && data.collections) ? data.collections : {};
	var collections_loaded = false;
	for (var key in collections) {
		if (collections.hasOwnProperty(key)) {
			collections_loaded = true;
			var collection = collections[key];
			var arr = scheduler.serverList[key];
			if (!arr) {
				scheduler.serverList[key] = arr = [];
			}
			arr.splice(0, arr.length); //clear old options
			for (var j = 0; j < collection.length; j++) {
				var option = collection[j];
				var obj = { key: option.value, label: option.label }; // resulting option object
				for (var option_key in option) {
					if (option.hasOwnProperty(option_key)) {
						if (option_key == "value" || option_key == "label")
							continue;
						obj[option_key] = option[option_key]; // obj['value'] = option['value']
					}
				}
				arr.push(obj);
			}
		}
	}
	if (collections_loaded)
		scheduler.callEvent("onOptionsLoad", []);

	var evs = [];
	for (var i = 0; i < events.length; i++) {
		var event = events[i];
		scheduler._init_event(event);
		evs.push(event);
	}
	return evs;
};*/
scheduler.parse = function(data, type) {
	this._process = type;
	this.on_load({xmlDoc: {responseText: data}});
};
scheduler.load = function(url, call) {
	if (typeof call == "string") {
		this._process = call;
		call = arguments[2];
	}

	this._load_url = url;
	this._after_call = call;
	this._load(url, this._date);
};
//possible values - day,week,month,year,all
scheduler.setLoadMode = function(mode) {
	if (mode == "all") mode = "";
	this._load_mode = mode;
};

scheduler.serverList = function(name, array) {
	if (array) {
		this.serverList[name] = array.slice(0);
		return this.serverList[name];
	}
	this.serverList[name] = (this.serverList[name] || []);
	return this.serverList[name];
};
scheduler._userdata = {};

scheduler._xmlNodeToJSON = function(node) {
	var t = {};
	for (var i = 0; i < node.attributes.length; i++)
		t[node.attributes[i].name] = node.attributes[i].value;

	for (var i = 0; i < node.childNodes.length; i++) {
		var child = node.childNodes[i];
		if (child.nodeType == 1)
			t[child.tagName] = child.firstChild ? child.firstChild.nodeValue : "";
	}

	if (!t.text) t.text = node.firstChild ? node.firstChild.nodeValue : "";

	return t;
};
scheduler.attachEvent("onXLS", function() {
	if (this.config.show_loading === true) {
		var t;
		t = this.config.show_loading = document.createElement("div");
		t.className = 'dhx_loading';
		t.style.left = Math.round((this._x - 128) / 2) + "px";
		t.style.top = Math.round((this._y - 15) / 2) + "px";
		this._obj.appendChild(t);
	}
});
scheduler.attachEvent("onXLE", function() {
	var t = this.config.show_loading;
	if (t && typeof t == "object") {
		if(t.parentNode) {
			t.parentNode.removeChild(t);
		}
		this.config.show_loading = true;
	}
});


}