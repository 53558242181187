export default function(scheduler){

scheduler.ext.fullscreen = {
	toggleIcon: null
};

scheduler.expand = function() {
	if(!scheduler.callEvent("onBeforeExpand", []))
		return;
	var t = scheduler._obj;
	do {
		t._position = t.style.position || "";
		t.style.position = "static";
	} while ((t = t.parentNode) && t.style);
	t = scheduler._obj;
	t.style.position = "absolute";
	t._width = t.style.width;
	t._height = t.style.height;
	t.style.width = t.style.height = "100%";
	t.style.top = t.style.left = "0px";

	var top = document.body;
	top.scrollTop = 0;

	top = top.parentNode;
	if (top)
		top.scrollTop = 0;
	document.body._overflow = document.body.style.overflow || "";
	document.body.style.overflow = "hidden";
	scheduler._maximize();
	scheduler.callEvent("onExpand", []);
};
scheduler.collapse = function() {
	if(!scheduler.callEvent("onBeforeCollapse", []))
		return;
	var t = scheduler._obj;
	do {
		t.style.position = t._position;
	} while ((t = t.parentNode) && t.style);
	t = scheduler._obj;
	t.style.width = t._width;
	t.style.height = t._height;
	document.body.style.overflow = document.body._overflow;
	scheduler._maximize();
	scheduler.callEvent("onCollapse", []);
};
scheduler.attachEvent("onTemplatesReady", function() {
	var t = document.createElement("div");
	t.className = "dhx_expand_icon";
	scheduler.ext.fullscreen.toggleIcon = t;
	t.innerHTML = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g>
	<line x1="0.5" y1="5" x2="0.5" y2="3.0598e-08" stroke="var(--dhx-scheduler-base-colors-icons)"/>
	<line y1="0.5" x2="5" y2="0.5" stroke="var(--dhx-scheduler-base-colors-icons)"/>
	<line x1="0.5" y1="11" x2="0.5" y2="16" stroke="var(--dhx-scheduler-base-colors-icons)"/>
	<line y1="15.5" x2="5" y2="15.5" stroke="var(--dhx-scheduler-base-colors-icons)"/>
	<line x1="11" y1="0.5" x2="16" y2="0.5" stroke="var(--dhx-scheduler-base-colors-icons)"/>
	<line x1="15.5" y1="2.18557e-08" x2="15.5" y2="5" stroke="var(--dhx-scheduler-base-colors-icons)"/>
	<line x1="11" y1="15.5" x2="16" y2="15.5" stroke="var(--dhx-scheduler-base-colors-icons)"/>
	<line x1="15.5" y1="16" x2="15.5" y2="11" stroke="var(--dhx-scheduler-base-colors-icons)"/>
	</g>
	</svg>
	`;
	scheduler._obj.appendChild(t);
	scheduler.event(t, "click",  function() {
		if (!scheduler.expanded)
			scheduler.expand(); else
			scheduler.collapse();
	});
});
scheduler._maximize = function() {
	this.expanded = !this.expanded;
	if(this.expanded){
		this.ext.fullscreen.toggleIcon.classList.add("dhx_expand_icon--expanded");
		
	}else{
		this.ext.fullscreen.toggleIcon.classList.remove("dhx_expand_icon--expanded");
	}
	
	//scheduler.ext.fullscreen.toggleIcon.style.backgroundPosition = "0 " + (this.expanded ? "0" : "18") + "px";

	var directions = ['left', 'top'];
	for (var i = 0; i < directions.length; i++) {
		//var margin = scheduler.xy['margin_' + directions[i]];
		var prev_margin = scheduler['_prev_margin_' + directions[i]];
		if (scheduler.xy['margin_' + directions[i]]) {
			scheduler['_prev_margin_' + directions[i]] = scheduler.xy['margin_' + directions[i]];
			scheduler.xy['margin_' + directions[i]] = 0;
		} else {
			if (prev_margin) {
				scheduler.xy['margin_' + directions[i]] = scheduler['_prev_margin_' + directions[i]];
				delete scheduler['_prev_margin_' + directions[i]];
			}
		}
	}

	scheduler.setCurrentView();
};


}
