import SchedulerFactory from "./scheduler_factory";
import gplExtensions from "./ext/extensions_gpl";

const factory = new SchedulerFactory(gplExtensions);

const scheduler = factory.getSchedulerInstance();
const Scheduler = {
	plugin: scheduler.bind(factory.plugin, factory)
};

window.scheduler = scheduler;
window.Scheduler = Scheduler;

if(!window.$dhx) {
	window.$dhx = {};
}

window.$dhx.scheduler = scheduler;
window.$dhx.Scheduler = Scheduler;

export default scheduler;export { scheduler, Scheduler };