export default function(scheduler){

scheduler.$keyboardNavigation.SchedulerNode = function(){};

scheduler.$keyboardNavigation.SchedulerNode.prototype = scheduler._compose(
	scheduler.$keyboardNavigation.EventHandler,
	{
		getDefaultNode: function(){
			var node = new scheduler.$keyboardNavigation.TimeSlot();

			if(!node.isValid()){
				node = node.fallback();
			}
			return node;
		},

		_modes:{
			month: "month",
			year: "year",
			dayColumns: "dayColumns",
			timeline:"timeline",
			units:"units",
			weekAgenda: "weekAgenda",
			list: "list"
		},
		getMode: function(){
			var state = scheduler.getState();

			var mode = state.mode;
			if((scheduler.matrix && scheduler.matrix[mode])){
				return this._modes.timeline;
			} else if((scheduler._props && scheduler._props[mode])){
				return this._modes.units;
			}else if(mode == "month"){
				return this._modes.month;
			}else if(mode == "year"){
				return this._modes.year;
			}else if(mode == "week_agenda"){
				return this._modes.weekAgenda;
			}else if(mode == "map" || mode == "agenda" || (scheduler._grid && scheduler["grid_" + mode])){
				return this._modes.list;
			}else{
				return this._modes.dayColumns;
			}
		},

		focus: function(){
			scheduler.focus();
		},

		blur: function(){

		},

		disable: function(){
			scheduler.$container.setAttribute("tabindex", "0");
		},
		enable: function(){
			if(scheduler.$container)
				scheduler.$container.removeAttribute("tabindex");
		},
		isEnabled: function(){
			return scheduler.$container.hasAttribute("tabindex");
		},


		_compareEvents: function(a, b){
			if (a.start_date.valueOf() == b.start_date.valueOf())
				return a.id > b.id ? 1 : -1;
			return a.start_date.valueOf() > b.start_date.valueOf() ? 1 : -1;
		},

		_pickEvent: function(from, to, startId, reverse){
			var range = scheduler.getState();
			from = new Date(Math.max(range.min_date.valueOf(), from.valueOf()));
			to = new Date(Math.min(range.max_date.valueOf(), to.valueOf()));

			var evs = scheduler.getEvents(from, to);
			evs.sort(this._compareEvents);
			if(reverse){
				evs = evs.reverse();
			}

			var trim = !!startId;
			for(var i =0; i < evs.length && trim; i++){
				if(evs[i].id == startId){
					trim = false;
				}
				evs.splice(i, 1);
				i--;
			}

			for(var i = 0; i < evs.length; i++){
				var eventElement = new scheduler.$keyboardNavigation.Event(evs[i].id);
				if(eventElement.getNode())
					return evs[i];
			}

			return null;
		},

		nextEventHandler: function(id){
			var activeNode = scheduler.$keyboardNavigation.dispatcher.activeNode;

			var startId = id || (activeNode && activeNode.eventId);

			var nextEvent = null;
			if(startId && scheduler.getEvent(startId)){
				var currEvent = scheduler.getEvent(startId);

				nextEvent = scheduler.$keyboardNavigation.SchedulerNode.prototype._pickEvent(
					currEvent.start_date,
					scheduler.date.add(currEvent.start_date, 1, "year"),
					currEvent.id,
					false
				);

			}
			if(!nextEvent && !id){
				var visibleDates = scheduler.getState();

				nextEvent = scheduler.$keyboardNavigation.SchedulerNode.prototype._pickEvent(
					visibleDates.min_date,
					scheduler.date.add(visibleDates.min_date, 1, "year"),
					null,
					false
				);
			}

			if(nextEvent){

				var nextEv = new scheduler.$keyboardNavigation.Event(nextEvent.id);
				if(!nextEv.isValid()){// not visible event
					this.nextEventHandler(nextEvent.id);
				}else{
					if(activeNode){activeNode.blur();}
					scheduler.$keyboardNavigation.dispatcher.setActiveNode(nextEv);
				}
			}
		},

		prevEventHandler: function(id){
			var activeNode = scheduler.$keyboardNavigation.dispatcher.activeNode;

			var startId = id || (activeNode && activeNode.eventId);

			var nextEvent = null;
			if(startId && scheduler.getEvent(startId)){
				var currEvent = scheduler.getEvent(startId);

				nextEvent = scheduler.$keyboardNavigation.SchedulerNode.prototype._pickEvent(
					scheduler.date.add(currEvent.end_date, -1, "year"),
					currEvent.end_date,
					currEvent.id,
					true
				);
			}
			if(!nextEvent && !id){
				var visibleDates = scheduler.getState();

				nextEvent = scheduler.$keyboardNavigation.SchedulerNode.prototype._pickEvent(
					scheduler.date.add(visibleDates.max_date, -1, "year"),
					visibleDates.max_date,
					null,
					true
				);
			}

			if(nextEvent){
				var nextEv = new scheduler.$keyboardNavigation.Event(nextEvent.id);
				if(!nextEv.isValid()){// not visible event
					this.prevEventHandler(nextEvent.id);
				}else{
					if(activeNode){activeNode.blur();}
					scheduler.$keyboardNavigation.dispatcher.setActiveNode(nextEv);
				}
			}
		},

		keys: {

			"alt+1, alt+2, alt+3, alt+4, alt+5, alt+6, alt+7, alt+8, alt+9": function(e){
				var tabs = scheduler.$keyboardNavigation.HeaderCell.prototype.getNodes(".dhx_cal_navline .dhx_cal_tab");
				var key = e.key;
				if(key === undefined){
					key = e.keyCode - 48;
				}
				if(tabs[key*1 - 1]){
					tabs[key*1 - 1].click();
				}
			},

			"ctrl+left,meta+left": function(e){
				scheduler._click.dhx_cal_prev_button();
			},
			"ctrl+right,meta+right": function(e){
				scheduler._click.dhx_cal_next_button();
			},
			"ctrl+up,meta+up":function(e){
				var dataArea = scheduler.$container.querySelector(".dhx_cal_data");
				dataArea.scrollTop -= 20;
			},
			"ctrl+down,meta+down": function(e){
				var dataArea = scheduler.$container.querySelector(".dhx_cal_data");
				dataArea.scrollTop += 20;
			},


			"e": function(){
				this.nextEventHandler();
			},

			"home": function(){
				scheduler.setCurrentView(new Date());
			},

			"shift+e": function(){
				this.prevEventHandler();
			},

			"ctrl+enter,meta+enter": function(){
				scheduler.addEventNow({start_date: new Date(scheduler.getState().date)});
			},

			"ctrl+c,meta+c": function(e){
				scheduler._key_nav_copy_paste(e);
			},
			"ctrl+v,meta+v": function(e){
				scheduler._key_nav_copy_paste(e);
			},
			"ctrl+x,meta+x": function(e){
				scheduler._key_nav_copy_paste(e);
			}

		}
	}
);

scheduler.$keyboardNavigation.SchedulerNode.prototype.bindAll(scheduler.$keyboardNavigation.SchedulerNode.prototype.keys);

}