

import dhtmlxHook from "./dhtmlx_hook";
import "./css/theme.less";
dhtmlxHook();

import global from "./global";

import factoryMethod from "./scheduler_factory_method";
import ExtensionsManager from "./ext/extension_manager";

class SchedulerFactory {

	constructor (extensions) {
		this._seed = 0;
		this._schedulerPlugins = [];
		this._bundledExtensions = extensions;
		this._extensionsManager = new ExtensionsManager(extensions);
	}

	plugin(code) {
		this._schedulerPlugins.push(code);

		if(global.scheduler){
			code(global.scheduler);
		}
	}
	
	getSchedulerInstance(initConfig) {
		var scheduler = factoryMethod(this._extensionsManager);

		for (var i = 0; i < this._schedulerPlugins.length; i++) {
			this._schedulerPlugins[i](scheduler);
		}
		scheduler._internal_id = this._seed++;
	
		if (this.$syncFactory){
			this.$syncFactory(scheduler);
		}

		if(initConfig){
			this._initFromConfig(scheduler, initConfig);
		}

		return scheduler;
	}

	_initFromConfig(scheduler, initConfig){
		if(initConfig.plugins){
			scheduler.plugins(initConfig.plugins);
		}
	
		if(initConfig.config){
			scheduler.mixin(scheduler.config, initConfig.config, true);
		}
	
		if(initConfig.templates){
			scheduler.attachEvent("onTemplatesReady", function(){
				scheduler.mixin(scheduler.templates, initConfig.templates, true);
			}, {once: true});
		}
	
		if(initConfig.events){
			for(const event in initConfig.events){
				scheduler.attachEvent(event, initConfig.events[event]);
			}
		}
	
		if(initConfig.locale){
			scheduler.i18n.setLocale(initConfig.locale);
		}
	
		if(Array.isArray(initConfig.calendars)){
			initConfig.calendars.forEach(function(calendar){
				scheduler.addCalendar(calendar);
			});
		}
	
		if(initConfig.container){
			scheduler.init(initConfig.container);
		} else {
			scheduler.init();
		}
	
		if(initConfig.data){
			if(typeof initConfig.data === "string"){
				scheduler.load(initConfig.data);
			}else{
				scheduler.parse(initConfig.data);
			}
		}
	}
	

}

export default SchedulerFactory;